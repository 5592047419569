import Style from "../../assets/css/panel.module.css";
import {useTranslation} from "react-i18next";

const News = () => {

    const { t } = useTranslation();
    const translate = t;

    return (
        <>
            <h6>{translate('menu_economic_calendar')}</h6>
            <div className={`${Style.PanelBox} ${Style.PanelBoxPadding} ${Style.NewsBox}`}>
                <iframe
                    src="https://www.tradays.com/en/economic-calendar/widget?mode=2&utm_source=my.wingomarkets.com"
                    height="275px"
                    width="100%"
                    style={{border: "unset"}}
                />
            </div>
        </>
    )
}

export default News;