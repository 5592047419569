import PanelLayout from "../../../layout/panel";
import {
    clearTrustedDevicesApi,
    disableTwoFactorApi,
    enableTwoFactorApi, generateNewBackupCodesApi,
    generateTwoFactorQRCodeApi, getProfileApi
} from "../../../api/api";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {Button, Col, Input, message, Row, Spin} from "antd";
import Style from "../../../assets/css/panel.module.css";
import ProfileTabs from "../../../components/profile/tabs";
import Verified from "../../../assets/img/icon/check-verified.svg";
import NotVerified from "../../../assets/img/icon/not-verified.svg";
import Google from "../../../assets/img/two-fa/google.svg";
import Copy from "../../../assets/img/icon/copy.svg";
import { DeleteOutlined, PicLeftOutlined } from '@ant-design/icons';
import {useTranslation} from "react-i18next";
import {useWindowSize} from "../../../layout/components/windowSize";

const TwoFactor = () => {

    const token = useSelector((state) => state.auth?.token || null);
    const dir = useSelector((state) => state.auth?.setting?.dir || "ltr");
    const { t } = useTranslation();
    const translate = t;
    const [twoFactorData, setTwoFactorData] = useState({});
    const [qrCode, setQrCode] = useState({});
    const [codes, setCodes] = useState([]);
    const [step, setStep] = useState(null);
    const [twoFactorStatus, setTwoFactorStatus] = useState(false);
    const [loading, setLoading] = useState(true);
    const [backup, setBackup] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [backupCodes, setBackupCodes] = useState([]);
    const {device} = useWindowSize();

    useEffect(()=>{
        getProfile();
        document.title = `${translate('wingo')} - ${translate('menu_two_factor_authentication')}`;
    },[])

    const getProfile = async () => {
        await getProfileApi(token).then((result)=>{
            if(!result.data.twoFactorAuthEnabled){
                setStep(2)
            } else {
                setStep(1)
                setTwoFactorStatus(true)
            }
            setLoading(false)
        }).catch((error)=> {
            console.log(error)
        })
    }

    const generateTwoFactorQRCode = async () => {
        await generateTwoFactorQRCodeApi(token).then((result)=>{
            setQrCode(result.data)
        }).catch((error)=>{
            setStep(1)
            console.log(error)
        })
    }

    const handleCopy = (v) => {
        message.success(translate('copied'))
        navigator.clipboard.writeText(v)
    }

    const enableTwoFactor = async () => {
        await enableTwoFactorApi(twoFactorData, token).then((result)=> {
            setCodes(result.data)
            setStep(1)
            setTwoFactorStatus(true)
            message.success(translate('two_fa_enable'))
        }).catch(()=>{
            message.error(translate('two_fa_wrong'))
        })
    }

    const disableTwoFactor = async () => {
        await disableTwoFactorApi(token).then(()=>{
            message.success(translate('two_fa_disable'))
            setStep(2)
        }).catch((error)=> {
            console.log(error)
        })
    }

    const clearTrustedDevices = async () => {
        await clearTrustedDevicesApi(token).then(()=>{
            message.success(translate('two_fa_clear_trusted'))
        }).catch((error)=> {
            console.log(error)
        })
    }

    const generateNewBackupCodes = async () => {
        setButtonLoading(true)
        await generateNewBackupCodesApi(token).then((result)=>{
            message.success(translate('two_fa_error_backup'))
            setBackup(true)
            setButtonLoading(false)
            setBackupCodes(result.data.codes)
        }).catch((error)=> {
            console.log(error)
        })
    }

    return (
        <PanelLayout>
            <div className={Style.Main}>
                <h1>{translate('profile')}</h1>
                <ProfileTabs />
                <h2>{translate('menu_two_factor_authentication')}</h2>
                <div className={`${Style.PanelBox} ${Style.PanelWrapperPadding}`}>

                    {loading ? <Spin /> : null}

                    {(step === 1 || step === 2) &&
                        (twoFactorStatus ?
                                <div className={Style.AccountVerified}>
                                    <img width={21} height={21} src={Verified} />
                                    <span className="dark-green-color" style={{ marginLeft: dir === "ltr" ? "5px" : null, marginRight: dir === "rtl" ? "5px" : null, display: "inline-block" }}>{translate('two_factor_enabled')}</span>
                                </div>
                                :
                                <div className={Style.AccountVerified}>
                                    <img width={21} height={21} src={NotVerified} />
                                    <span style={{ marginLeft: dir === "ltr" ? "5px" : null, display: "inline-block", marginRight: dir === "rtl" ? "5px" : null, color: "red" }}>{translate('two_factor_disabled')}</span>
                                </div>
                        )
                    }

                    {step === 0 &&
                        <div className={Style.TWoFaForm}>
                            <span>{translate('two_factor_google_title')}</span>
                            <strong>{translate('two_factor_google_instruction')}</strong>

                            <Row>
                                <Col xxl={4} xl={5} lg={6} md={5}>
                                    {qrCode?.url ? <img src={qrCode?.url} alt="QR Code" /> : <Spin />}
                                </Col>
                                <Col xxl={20} xl={19} lg={18} md={19}>
                                    <label style={{ marginBottom: "15px", display: "block" }}>{translate('two_factor_message')}</label>
                                    <div className={Style.TwoFactorCode} onClick={(e) => handleCopy(qrCode?.manualCode)}>
                                        <bdi>{qrCode?.manualCode}</bdi>
                                        <img width={24} height={24} src={Copy} alt="Copy" />
                                    </div>
                                    <div className="d-md-flex align-items-center">
                                        <label style={dir === "ltr" ? { marginRight: "20px" } : { marginLeft: "20px" }} className="text-nowrap">{translate('two_factor_enter_code')}</label>
                                        <Input className="deposit-select" style={{ borderRadius: "25px" }} onChange={(e) => setTwoFactorData({ ...twoFactorData, code: e.target.value })} />
                                        <Button style={device != "mobile" ? { marginLeft: dir === "ltr" ? "20px" : null, marginRight: dir === "rtl" ? "20px" : null } : {marginTop: "15px"}} shape="round" className="dark-green-button white-color" disabled={!twoFactorData?.code} onClick={() => enableTwoFactor()}>{translate('two_factor_enable')}</Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    }

                    {step === 1 &&
                        <div>
                            <div className="d-md-flex">
                                <Button icon={<img width={23} height={20} src={Google} />} onClick={() => disableTwoFactor()} shape="round" danger className="d-flex">{translate('two_factor_disable')}</Button>
                                <Button style={device === "mobile" ? {marginTop: "15px"} : (dir === "ltr" ? { marginLeft: "20px" } : { marginLeft: "20px" })} shape="round" icon={<DeleteOutlined />} onClick={() => clearTrustedDevices()}>{translate('two_factor_truest')}</Button>
                            </div>

                            <div className={Style.TwoFactorBackup}>
                                <h3>{translate('two_factor_backup')}</h3>
                                <p>{translate('two_factor_backup_desc')}</p>
                                {!backup ?
                                    <span style={{ color: "red" }}>{translate('two_factor_backup_not_generated')}</span> :
                                    <span className="dark-green-color">{translate('two_factor_backup_generated')}</span>
                                }
                                <div style={{ marginTop: "20px", width: "200px" }} onClick={()=>handleCopy(backupCodes?.toString())}>
                                    <Row gutter={10} data-codes={backupCodes.toString()}>
                                        {backupCodes?.map((Backup, Index) => {
                                            return (
                                                <Col md={8} style={{marginBottom: "10px"}} key={Index}>{Backup}</Col>
                                            )
                                        })}
                                    </Row>
                                </div>
                                <Button loading={buttonLoading} style={{ marginTop: "25px" }} shape="round" icon={<PicLeftOutlined />} onClick={() => generateNewBackupCodes()}>{translate('two_factor_generate')}</Button>
                            </div>
                        </div>
                    }

                    {step === 2 &&
                        <div>
                            <div className="d-md-flex align-items-center">
                                <Button icon={<img width={23} height={20} src={Google} />} onClick={() => (setStep(0), generateTwoFactorQRCode())} shape="round" className="dark-green-button white-color d-flex">{translate('enable_two_factor')}</Button>
                                <span style={dir === "ltr" ? { marginLeft: "10px" } : { marginRight: "10px" }} className="gray-color">{translate('two_factor_via_google')}</span>
                            </div>
                            <div style={{ marginTop: "100px" }}>
                                <Row gutter={74}>
                                    <Col md={8} className={`${Style.TwoFactorItems} ant-col-full`}>
                                        <a href="https://en.wikipedia.org/wiki/Multi-factor_authentication" className={Style.TwoFactorLink} target="_blank" rel="noopener noreferrer">
                                            <span>{translate('what_is_two_factor')}</span>
                                        </a>
                                    </Col>
                                    <Col md={8} className={`${Style.TwoFactorItems} ant-col-full`}>
                                        <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" className={Style.TwoFactorLink} target="_blank" rel="noopener noreferrer">
                                            <span>{translate('two_factor_android')}</span>
                                        </a>
                                    </Col>
                                    <Col md={8} className={`${Style.TwoFactorItems} ant-col-full`}>
                                        <a href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8" className={Style.TwoFactorLink} target="_blank" rel="noopener noreferrer">
                                            <span>{translate('two_factor_ios')}</span>
                                        </a>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </PanelLayout>
    )
}

export default TwoFactor;