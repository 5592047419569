import Style from "../../../assets/css/panel.module.css";
import AccountsTabs from "../../../components/accounts/tabs";
import {Row, Col, Steps, Button, Select, Modal, message, Badge, Skeleton, notification} from "antd";
import PanelLayout from "../../../layout/panel";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {createNewAccountApi, getAccountsTypesApi, getCurrenciesApi} from "../../../api/api";
import {useSelector} from "react-redux";
import Verified from "../../../assets/img/icon/check-verified-color.svg";
import {Link, useNavigate} from "react-router-dom";
import Download from "../../../assets/img/icon/download.svg";
import {initialBalance, leverage} from "../../../api/data";
import Copy from "../../../assets/img/icon/copy.svg";
import Guides from "../../../components/guides";

const NewAccount = () => {

    const token = useSelector((state) => state.auth?.token || null);
    const { t } = useTranslation();
    const translate = t;
    const [accountsTypes, setAccountsTypes] = useState([]);
    const [accountsTypesOptions, setAccountsTypesOptions] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const navigate = useNavigate();
    const dir = useSelector((state) => state.auth?.setting?.dir || null);
    const [modal, setModal] = useState(false);
    const [step, setStep] = useState(0);
    const [type, setType] = useState(false);
    const [accountData, setAccountData] = useState({});
    const [newAccount, setNewAccount] = useState({});
    const [loading, setLoading] = useState(false);
    const [accountType, setAccountType] = useState({});
    const [openFeature, setOpenFeature] = useState(null);
    const lang = useSelector((state) => state.auth?.setting?.lang || "en");

    useEffect(()=>{
        if(token){
            getAccountsTypes()
            getCurrencies()
        }
        document.title = `${translate('wingo')} - ${translate('dashboard_new_account')}`;
    },[]);

    const AccountFeaturesArray = [
        {
            id: 3,
            commission: 0,
            spread: 1,
            leverage: "1:400",
            margin: 100,
            stop: 30
        },
        {
            id: 4,
            commission: 8,
            spread: 0.4,
            leverage: "1:400",
            margin: 100,
            stop: 30
        },
        {
            id: 5,
            commission: 6,
            spread: 0.2,
            leverage: "1:400",
            margin: 100,
            stop: 30
        },
        {
            id: 6,
            commission: 4,
            spread: 0.1,
            leverage: "1:400",
            margin: 100,
            stop: 30
        },
        {
            id: 12,
            commission: 0,
            spread: 0.4,
            leverage: "1:400",
            margin: 100,
            stop: 30
        },
        {
            id: 13,
            commission: 6,
            spread: 0.1,
            leverage: "1:400",
            margin: 100,
            stop: 30
        }
    ];


    const translatedFeaturesArray = AccountFeaturesArray.map(account => ({
        id: account.id,
        features: [
            account.commission === 0
                ? t("no_commission")
                : t("commission", { commission: account.commission }),
            t("spreadsFrom", { spread: account.spread }),
            t("leverageUpTo", { leverage: account.leverage }),
            t("marginCall", { margin: account.margin }),
            t("stopOut", { stop: account.stop })
        ]
    }));

    const getAccountFeatures = (id) => {
        const list = translatedFeaturesArray?.find((x)=>x.id === id)?.features;
        return <ul key={id} className={Style.AccountTypeFeaturesList}>
            {list?.map((Feature, Index)=>{
                return (
                    <li key={Index}>{Feature}</li>
                )
            })}
        </ul>
    }

    const getAccountsTypes = async () => {
        await getAccountsTypesApi(token).then((result)=>{
            result.data.filter((x)=> (x.label = x.title, x.value = x.id))

            const groupedAccounts = {};
            const groupedOptions = [];

            result.data.forEach(account => {
                const title = account.id === 12 ?
                    "Social Trading"
                    : account.id === 13 ?
                        "Social Trading VIP"
                    : account.title.split(" ")[0];
                if (groupedAccounts[title]) {
                    groupedAccounts[title].push(account);
                } else {
                    groupedAccounts[title] = [account];
                }
            });

            /*Object.keys(groupedAccounts).forEach(title => {
                const options = groupedAccounts[title].map(account => ({
                    label: <span>{account.title}</span>,
                    value: account.id,
                }));
                groupedOptions.push({
                    label: <span>{title} Account</span>,
                    title: title,
                    options: options,
                });
            });*/

            setAccountsTypes(groupedAccounts)
            setAccountsTypesOptions(result.data)

        }).catch((error)=> {
            console.log(error)
        })
    }

    const getCurrencies = async () => {
        await getCurrenciesApi(token).then((result)=>{
            const convertedArray = result.data.map(currencyCode => ({
                label: currencyCode,
                value: currencyCode,
            }));
            const usd = [
                {
                    label: "USD",
                    value: "USD"
                }
            ]
            setCurrencies(usd)
        }).catch((error)=> {
            console.log(error)
        })
    }

    useEffect(()=>{
        if(type){
            setAccountData({...accountData, typeId: type})
            const find = accountsTypes.find((x)=> x.id === type);
            if(find){
                setAccountType({title: find?.title})
            } else {
                setAccountType({title: "Demo Account"})
            }
        }
    },[type]);

    const handleSteps = () => {
        if(step === 0){
            setStep(1)
        } else if(step === 1){
            createAccount()
        } else if (step === 2) {
            setModal(false)
            setStep(0)
            setAccountData({})
        }
    }

    const handleCopy = (v) => {
        message.success(translate('copied'))
        navigator.clipboard.writeText(v)
    }

    const createAccount = async () => {
        setLoading(true)
        await createNewAccountApi(accountData, token).then((result)=>{
            notification.success({
                message: translate('error_created')
            })
            setStep(2)
            setLoading(false)
            setNewAccount(result.data)
        }).catch((error)=>{
            const message = error?.response?.data?.message;
            notification.error({
                message: message
            })
            setLoading(false)
        })
    }

    const handleSetAccountType = (value) => {
        setModal(true)
        setAccountData({ ...accountData, typeId: value.id })
        setAccountType(value)
    }

    return (
        <PanelLayout>
            <div className={Style.Main}>
                <h1>{translate('menu_my_accounts')}</h1>
                <Row gutter={10}>
                    <Col md={12}>
                        <Guides type="createLiveAccount" locale={lang}/>
                    </Col>
                    <Col md={12}>
                        <Guides type="createDemoAccount" locale={lang}/>
                    </Col>
                </Row>

                <AccountsTabs />

                <h2>{translate('dashboard_new_account')}</h2>
                <div className={`${Style.PanelBox} ${Style.PanelBoxPadding}`}>
                    <Row gutter={[40, 40]} className={Style.NoGutter}>
                    {Object.keys(accountsTypes).length ?
                            Object.keys(accountsTypes)?.map((AccountType, Index) => {
                            return (
                                <Col
                                    sm={24}
                                    md={12}
                                    lg={12}
                                    xl={AccountType === "Social Trading" ? 12
                                        : AccountType === "Social Trading VIP" ? 12
                                            : 6}
                                     key={Index}
                                >
                                    {AccountType === "Social Trading VIP" ?
                                        <Badge.Ribbon text="VIP"/> : null}
                                    <div className={Style.AccountTypes}>
                                        <div className={AccountType === "VIP" ? `${Style.AccountTypesTitle} ${Style.AccountTypesTitleSpecial}` : `${Style.AccountTypesTitle}`}>
                                            <strong>{AccountType}</strong>
                                            <span>{translate('funds_account')}</span>
                                        </div>
                                        <div className={AccountType === "Social" ? Style.AccountTypeSocial : null}>
                                            {accountsTypes[AccountType]?.map((Account, Index) => {
                                                return (
                                                    <div key={Index} className={Account.category === "live" ? Style.AccountTypesBody : null}>
                                                        {Account.category === "live" ?
                                                            <>
                                                                <div className={Style.AccountTypesPrice}>
                                                                    <div className={Style.AccountTypesAmount}>
                                                                        <bdi>$</bdi>
                                                                        <span>{
                                                                            Account.id === 3 ? "100"
                                                                            : Account.id === 4 ? "500"
                                                                                : Account.id === 5 ? "2000"
                                                                                    : Account.id === 6 ? "10000"
                                                                                        : Account.id === 12 ? "200"
                                                                                            : Account.id === 13 ? "5000" : null}</span>

                                                                    </div>
                                                                    <p>{translate('minimum_funding')}</p>
                                                                </div>
                                                                <div className={Style.AccountTypeFeatures}>
                                                                    <span>{translate('features')}</span>
                                                                    {getAccountFeatures(Account.id)}
                                                                </div>
                                                            </>
                                                            : null}

                                                        <div className="d-flex align-items-center justify-content-center">
                                                            {Account.category === "live" ?
                                                                <Button
                                                                    size="large"
                                                                    className={Style.AccountTypesButton}
                                                                    onClick={() => handleSetAccountType(Account)}
                                                                    shape="round"
                                                                >{translate('create_account')}</Button>
                                                                :
                                                                <Button
                                                                    size="large"
                                                                    shape="round"
                                                                    className={Style.AccountTypesDemo}
                                                                    onClick={() => handleSetAccountType(Account)}
                                                                >{translate('menu_demo_account')}</Button>}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </Col>
                            )
                        }) :
                            <>
                                <Col sm={24} md={12} lg={12} xl={6}>
                                    <Skeleton/>
                                </Col>
                                <Col sm={24} md={12} lg={12} xl={6}>
                                    <Skeleton/>
                                </Col>
                                <Col sm={24} md={12} lg={12} xl={6}>
                                    <Skeleton/>
                                </Col>
                                <Col sm={24} md={12} lg={12} xl={6}>
                                    <Skeleton/>
                                </Col>
                                <Col sm={24} md={12} lg={12} xl={12}>
                                    <Skeleton/>
                                </Col>
                                <Col sm={24} md={12} lg={12} xl={12}>
                                    <Skeleton/>
                                </Col>
                            </>
                        }
                    </Row>

                </div>
            </div>

            <Modal
                className={dir === "rtl" ? `${Style.RtlStyle} rtl` : null}
                width={893}
                open={modal}
                title={[
                    <div key={1}>
                        <div className={Style.AccountModalHeader}>
                            {step === 2 ? (
                                <div>
                                    <div className="d-md-flex justify-content-center">
                                        <img src={Verified} />
                                    </div>
                                    <div className="text-center">
                                        <p>{translate('account_created_text1', {id: newAccount?.login})}</p>
                                        <p>{translate('account_created_text2')}.</p>
                                    </div>
                                </div>
                            ) : (
                                <span>{translate('create_new_account')}</span>
                            )}
                        </div>
                        {step !== 2 ? <Steps className="account-steps" current={step} items={[{ title: translate('account_type'), content: 'a' }, { title: translate('funds_confirmation'), content: 'b' }]} /> : null}
                    </div>
                ]}
                closable={false}
                footer={[
                    <div key={1} className="d-md-flex align-items-center justify-content-center">
                        <div className="d-flex" style={{ padding: '50px' }}>
                            {step === 2 ? (
                                <Link to="/platform" className={`${Style.AccountModalReviewDownload} d-flex align-items-center`}>
                                    <img src={Download} />
                                    <strong className={'dark-green-color'} style={{ marginRight: '30px', marginLeft: '15px' }}>
                                        {translate('download_trading_terminal')}
                                    </strong>
                                </Link>
                            ) : null}
                            <Button
                                size="large"
                                shape="round"
                                onClick={() => (step === 0 ? setModal(false) : step === 1 ? setStep(0) : step === 2 ? (navigate('/accounts/overview'), setModal(false), setAccountData({}), setStep(0)) : null)}
                            >
                                {step === 2 ? translate('menu_accounts_overview') : translate('back')}
                            </Button>
                            <Button
                                disabled={!accountData?.typeId || !accountData?.leverage || !accountData?.currency || (accountData?.typeId === 7 && !accountData?.initialBalance)}
                                onClick={() => handleSteps()}
                                className="dark-green-button white-color"
                                shape="round"
                                size="large"
                                loading={loading}
                            >
                                {step === 2 ? translate('close') : translate('continue')}
                            </Button>
                        </div>
                    </div>
                ]}
            >
                {step === 0 ? (
                    <div className={Style.AccountModalFields}>
                        <div className={Style.AccountModalField}>
                            <label data-disabled={!accountsTypes}>{translate('type')}</label>
                            {/*<Select
                                value={accountData?.typeId}
                                style={{ width: '100%' }}
                                placeholder={translate('select_option')}
                                options={accountsTypesOptions}
                                onChange={(choice,_value) => (setAccountData({ ...accountData, typeId: choice }), setAccountType(_value))}
                                disabled={!accountsTypes}
                            />*/}
                            <span style={{fontSize: "16px"}}>{accountType?.title}</span>
                        </div>

                        <div className={Style.AccountModalField}>
                            <label data-disabled={!accountData?.typeId}>{translate('funds_leverage')}</label>
                            <Select
                                value={accountData?.leverage}
                                style={{ width: '100%' }}
                                placeholder={translate('select_option')}
                                options={leverage}
                                onChange={(choice) => setAccountData({ ...accountData, leverage: choice })}
                                disabled={!accountData?.typeId}
                            />
                        </div>

                        <div className={Style.AccountModalField}>
                            <label data-disabled={!accountData?.leverage}>{translate('funds_currency')}</label>
                            <Select
                                value={accountData?.currency}
                                style={{ width: '100%' }}
                                placeholder={translate('select_option')}
                                options={currencies}
                                onChange={(choice) => setAccountData({ ...accountData, currency: choice })}
                                disabled={!accountData?.leverage}
                            />
                        </div>

                        {type == 'demo' || accountData?.typeId === 7 || accountData?.typeId === 14 || accountData?.typeId === 15 || accountData?.typeId === 16 ? (
                            <div className={Style.AccountModalField}>
                                <label data-disabled={!accountData?.currency}>{translate('initial_balance')}</label>
                                <Select
                                    value={accountData?.initialBalance}
                                    style={{ width: '100%' }}
                                    placeholder={translate('initial_balance')}
                                    options={initialBalance}
                                    onChange={(choice) => setAccountData({ ...accountData, initialBalance: choice })}
                                    disabled={!accountData?.currency}
                                />
                            </div>
                        ) : null}
                    </div>
                ) : step === 1 ? (
                    <div style={{ marginTop: '30px' }}>
                        <ul className={`${Style.DepositReview} ${Style.AccountModalReview} list-unstyled m-0 p-0`}>
                            <li>
                                <span>{translate('account_type')}</span>
                                <bdi>{accountType?.title}</bdi>
                            </li>
                            <li>
                                <span>{translate('funds_leverage')}</span>
                                <bdi>{accountData?.leverage}</bdi>
                            </li>
                            <li>
                                <span>{translate('funds_currency')}</span>
                                <bdi>{accountData?.currency}</bdi>
                            </li>
                            {type == 'demo' || accountData?.typeId === 7 ?
                                <li>
                                    <span>{translate('funds_balance')}</span>
                                    <bdi>{accountData?.initialBalance} USD</bdi>
                                </li> : null}
                        </ul>
                    </div>
                ) : step === 2 ? (
                    <div style={{ marginTop: '30px' }}>
                        <ul className={`${Style.DepositReview} ${Style.AccountModalReview} list-unstyled m-0 p-0`}>
                            <li>
                                <span>{translate('accounts_login')}</span>
                                <bdi>{newAccount?.login}</bdi>
                                <div className={Style.AccountModalReviewCopy} onClick={() => handleCopy(newAccount?.login)}>
                                    <img src={Copy} width={22} height={22} />
                                </div>
                            </li>
                            <li>
                                <span>{translate('account_server')}</span>
                                <bdi>{newAccount?.type?.server}</bdi>
                                <div className={Style.AccountModalReviewCopy} onClick={() => handleCopy(newAccount?.type?.server)}>
                                    <img src={Copy} width={22} height={22} />
                                </div>
                            </li>
                            <li>
                                <span>{translate('funds_leverage')}</span>
                                <bdi>{newAccount?.leverage}</bdi>
                                <div className={Style.AccountModalReviewCopy} onClick={() => handleCopy(newAccount?.leverage)}>
                                    <img src={Copy} width={22} height={22} />
                                </div>
                            </li>
                            <li>
                                <span>{translate('profile_password')}</span>
                                <bdi>{newAccount?.password}</bdi>
                                <div className={Style.AccountModalReviewCopy} onClick={() => handleCopy(newAccount?.password)}>
                                    <img src={Copy} width={22} height={22} />
                                </div>
                            </li>
                            <li>
                                <span>{translate('account_investor_password')}</span>
                                <bdi>{newAccount?.investorPassword}</bdi>
                                <div className={Style.AccountModalReviewCopy} onClick={() => handleCopy(newAccount?.investorPassword)}>
                                    <img src={Copy} width={22} height={22} />
                                </div>
                            </li>
                            <li>
                                <span>{translate('funds_currency')}</span>
                                <bdi>{newAccount?.currency}</bdi>
                                <div className={Style.AccountModalReviewCopy} onClick={() => handleCopy(newAccount?.currency)}>
                                    <img src={Copy} width={22} height={22} />
                                </div>
                            </li>
                        </ul>
                    </div>
                ) : null}
            </Modal>
        </PanelLayout>
    )
}

export default NewAccount;