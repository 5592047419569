import PanelLayout from "../../layout/panel";
import Style from "../../assets/css/panel.module.css";
import ToolsTabs from "../../components/tools/tabs";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";

const Calendar = () => {

    const { t } = useTranslation();
    const translate = t;

    useEffect(()=>{
        document.title = `${translate('wingo')} - ${translate('menu_economic_calendar')}`;
    },[]);

    return (
        <PanelLayout>
            <div className={Style.Main}>
                <h1>{translate('menu_my_tools')}</h1>
                <ToolsTabs/>

                <div className={`${Style.PanelBox} ${Style.PanelBoxPadding}`}>
                    <h2>{translate('menu_economic_calendar')}</h2>

                    <iframe
                        src="https://www.tradays.com/en/economic-calendar/widget?mode=2&utm_source=my.wingomarkets.com"
                        height="600px"
                        width="100%"
                        style={{border: "unset"}}
                    />

                </div>
            </div>
        </PanelLayout>
    )
}

export default Calendar;