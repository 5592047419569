import PanelLayout from "../../../layout/panel";
import {Button, Col, Input, message, notification, Row, Select, Steps} from "antd";
import {useEffect, useState} from "react";
import Style from "../../../assets/css/panel.module.css";
import FundsTabs from "../../../components/funds/tabs";
import {useSelector} from "react-redux";
import {checkTransferApi, createNewTransferApi, getAccountApi} from "../../../api/api";
import {useNavigate, useParams} from "react-router-dom";
import Verified from "../../../assets/img/icon/check-verified.svg";
import {useTranslation} from "react-i18next";
import {useWindowSize} from "../../../layout/components/windowSize";
import Guides from "../../../components/guides";

const Transfer = () => {

    const { account_id } = useParams();
    const token = useSelector((state) => state.auth?.token || null);
    const { t } = useTranslation();
    const translate = t;
    const [loading, setLoading] = useState(false);
    const [ButtonLoading, setButtonLoading] = useState(false);
    const [step, setStep] = useState(0);
    const [accountsFrom, setAccountsFrom] = useState([]);
    const [accountsTo, setAccountsTo] = useState([]);
    const [transferData, setTransferData] = useState({});
    const [buttonStatus, setButtonStatus] = useState(true);
    const [selectedWallet, setSelectedWallet] = useState({});
    const [selectedDestination, setSelectedDestination] = useState({});
    const {device} = useWindowSize();
    const lang = useSelector((state) => state.auth?.setting?.lang || "en");

    useEffect(()=>{
        if(token){
            getAccounts();
        }
        document.title = `${translate('wingo')} - ${translate('menu_funds_transfer_funds')}`;
    },[]);

    useEffect(()=>{
        if(Object.entries(transferData).length){
            if(transferData?.fromLoginSid === transferData?.toLoginSid){
                setButtonStatus(true)
                message.error("Transfer From and Transfer To accounts cannot be the same")
            } else if(transferData?.fromLoginSid == undefined || transferData?.toLoginSid == undefined) {
                setButtonStatus(true)
            } else {
                setButtonStatus(false)
            }
        } else {
            setButtonStatus(true)
        }
    },[transferData])

    const groupByTypeTitle = (data) => {
        const groupedData = {};

        data.forEach(item => {
            const typeTitle = item.type?.title;

            if (!groupedData[typeTitle] && item.typeId !== 7) {
                groupedData[typeTitle] = {
                    label: typeTitle,
                    options: [],
                };
            }

            item.label = `${item.type?.title} - ${item.login} (${item.balance} ${item.currency})`;
            item.value = item.loginSid;

            if (groupedData[typeTitle] && item.typeId !== 7) {
                groupedData[typeTitle].options.push(item);
            }
        });

        return Object.values(groupedData);
    }

    const getAccounts = async () => {
        setLoading(true)
        const data = {
            category: null,
            scope: null
        }

        await getAccountApi(data, token).then((result)=>{

            const AccountsFrom = result.data;
            const AccountsTo = result.data.filter((x)=>x.typeId != 2);
            const AccountsArrayFrom = groupByTypeTitle(AccountsFrom);
            const AccountsArrayTo = groupByTypeTitle(AccountsTo);

            setAccountsFrom(AccountsArrayFrom)
            setAccountsTo(AccountsArrayTo)
            setLoading(false)

            if(account_id){
                const find = AccountsFrom?.find((x)=> x.loginSid == account_id);
                setTransferData({...transferData, fromLoginSid: find.loginSid})
            }

        }).catch((error)=> {
            console.log(error)
        })
    }

    const checkTransfer = async () => {
        setButtonLoading(true);
        let data = {...transferData};
        data.currency = "USD";

        await checkTransferApi(data, token).then(()=>{
            setButtonLoading(false);
            setStep(2)
        }).catch((error)=>{
            const err = error?.response?.data?.errors?.children;
            setButtonLoading(false);

            Object.keys(err).forEach((key) => {
                const errorObject = err[key];

                if (errorObject && errorObject.errors && errorObject.errors.length > 0) {
                    errorObject.errors.forEach((error) => {
                        notification.error({description: error})
                    });
                }
            });
        })
    }

    const createNewTransfer = async () => {
        setButtonLoading(true)
        await createNewTransferApi(transferData, token).then(()=>{
            setStep(3)
            setButtonLoading(false)
        }).catch((error)=> {
            console.log(error)
        })
    }

    const handleStep = () => {
        if(step === 0){
            setStep(1)
        } else if(step === 1){
            checkTransfer();
        } else if(step === 2){
            createNewTransfer();
        }
    }

    return (
        <PanelLayout>
            <div className={Style.Main}>
                <h1>{translate('menu_my_funds')}</h1>
                <Guides type="transferFunds" locale={lang}/>

                <FundsTabs/>
                <h2>{translate('menu_funds_transfer_funds')}</h2>

                <div className={Style.PanelBox}>
                    <Steps
                        className="deposit-steps"
                        current={step}
                        items={[
                            {
                                title: translate('funds_my_funds_deposit_initial_information'),
                                content: "a"
                            },
                            {
                                title: translate('funds_transfer_details'),
                                content: "b"
                            },
                            {
                                title: translate('funds_confirmation'),
                                content: "c"
                            }
                        ]}
                    />

                    <div className={Style.PanelWrapperPadding}>
                        {step === 0 ?
                            <>
                                <Row gutter={{md: 20, lg: 30, xl: 50, xxl: 90 }}>
                                    <Col md={12} className="ant-col-full">
                                        <div className={`${Style.DepositMethods} d-md-flex`}>
                                            <label className="text-nowrap gray-color">{translate('funds_transfer_from')}</label>
                                            <Select
                                                value={transferData?.fromLoginSid}
                                                disabled={loading}
                                                placeholder={translate('select_option')}
                                                options={accountsFrom}
                                                loading={loading}
                                                className="w-100 deposit-select"
                                                onChange={(choice, _value) => (setTransferData({ ...transferData, fromLoginSid: choice }), setSelectedWallet(_value))}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={12} className="ant-col-full" style={device === "mobile" ? {marginTop: "15px"} : null}>
                                        <div className={`${Style.DepositMethods} d-md-flex`}>
                                            <label className="text-nowrap gray-color">{translate('funds_transfer_to')}</label>
                                            <Select
                                                disabled={loading}
                                                placeholder={translate('select_option')}
                                                options={accountsTo}
                                                loading={loading}
                                                className="w-100 deposit-select"
                                                onChange={(choice, _value) => (setTransferData({ ...transferData, toLoginSid: choice }), setSelectedDestination(_value))}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </> : step === 1 ?
                                <>
                                    <Row gutter={40} className={Style.DepositMethodForm}>
                                        <Col md={12} className="ant-col-full">
                                            <div className={`${Style.DepositMethods} d-md-flex`}>
                                                <label className="text-nowrap gray-color">{translate('funds_transfer_amount')}</label>
                                                <div className="w-100">
                                                    <Input className="deposit-select" value={transferData?.amount} onChange={(e) => setTransferData({ ...transferData, amount: e.target.value })} />
                                                    <small>{selectedWallet?.type?.title} {selectedWallet?.login}: {translate('available')} {selectedWallet?.balance} {selectedWallet?.currency}</small>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className="ant-col-full" md={12} style={device === "mobile" ? {marginTop: "15px"} : null}>
                                            <div className={`${Style.DepositMethods} d-md-flex`}>
                                                <label className="text-nowrap gray-color">{translate('funds_credit_amount')}</label>
                                                <div className="w-100">
                                                    <Input type="number" className="deposit-select" value={transferData?.amount} readOnly />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className={`${Style.DepositMethod} ${Style.DepositNotes}`}>
                                        <p className="p-0 m-0">{translate('current_conversion_rate')}: 1.00000</p>
                                    </div>
                                </>
                                : step === 2 ?
                                    <>
                                        <ul className={`${Style.DepositReview} list-unstyled m-0 p-0`}>
                                            <li>
                                                <span>{translate('funds_transfer_from')}</span>
                                                <bdi>{selectedWallet?.type?.title} {selectedWallet?.login}</bdi>
                                            </li>
                                            <li>
                                                <span>{translate('funds_transfer_to')}</span>
                                                <bdi>{selectedDestination?.type?.title} {selectedDestination?.login}</bdi>
                                            </li>
                                            <li>
                                                <span>{translate('transfer_deducted', {param: selectedWallet?.login})}</span>
                                                <bdi>{transferData?.amount}</bdi>
                                            </li>
                                            <li>
                                                <span>{translate('transfer_credited', {param: selectedDestination?.login})}</span>
                                                <bdi>{transferData?.amount}</bdi>
                                            </li>
                                        </ul>
                                    </>
                                    : step === 3 ?
                                        <>
                                            <div className={Style.DepositSuccess}>
                                                <img width={87} height={87} src={Verified} />
                                                <p>{translate('transfer_done')}</p>
                                                <br/>
                                                <br/>
                                                <Button onClick={()=> setStep(0)} style={{borderRadius: "25px"}} size="large" className="dark-green-button white-color">{translate('new_transfer')}</Button>
                                            </div>
                                        </>
                                        : null
                        }

                        <div className={`d-flex justify-content-end ${Style.DepositMethodButtons}`}>
                            {step > 0 && step < 3 ? <Button shape="round" className="black-color" onClick={() => setStep(step > 0 ? step - 1 : 0)}>{translate('back')}</Button> : null}
                            {step < 3 ?
                                <Button
                                    shape="round"
                                    className="dark-green-button white-color"
                                    disabled={buttonStatus}
                                    onClick={() => handleStep()}
                                    loading={ButtonLoading}
                                >
                                    {step === 2 ? translate('confirm') : translate('continue')}
                                </Button> : null}
                        </div>
                    </div>

                </div>
            </div>
        </PanelLayout>
    )
}

export default Transfer;