import PanelLayout from "../../../layout/panel";
import Style from "../../../assets/css/panel.module.css";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import SocialTabs from "../../../components/social/tabs";
import Guides from "../../../components/guides";
import {useSelector} from "react-redux";

const SocialTradingProvider = () => {

    const { t } = useTranslation();
    const translate = t;
    const lang = useSelector((state) => state.auth?.setting?.lang || "en");

    useEffect(()=>{
        document.title = `${translate('wingo')} - ${translate('menu_web_terminal')}`;
    },[]);

    return (
        <PanelLayout>
            <div className={Style.Main}>
                <h1>{translate('menu_social_trading')}</h1>

                <Guides type="socialTrading" locale={lang}/>

                <div className={`${Style.PanelBox} ${Style.PanelBoxPadding}`}>
                    <SocialTabs/>
                    <h3>{translate('social_choose_provider')}</h3>
                    <iframe id="widgetFrame"
                            frameBorder="0"
                            height="800px"
                            width="100%"
                            src="https://social2.wingomarkets.com:8081"
                    ></iframe>
                </div>
            </div>
        </PanelLayout>
    )
}

export default SocialTradingProvider;