import PanelLayout from "../../../layout/panel";
import {useEffect, useState} from "react";
import axios from "axios";
import {Node_Api} from "../../../api/config";
import Style from "../../../assets/css/panel.module.css";
import {useTranslation} from "react-i18next";
import {Button, message, Modal, notification, Select, Statistic, Table} from "antd";
import {useSelector} from "react-redux";
import VPSTabs from "../../../components/vps/tabs";

const CreateVps = () => {

    const user = useSelector((state) => state.auth?.user || {});
    const { t } = useTranslation();
    const translate = t;
    const [serverTypes, setServerTypes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingVps, setLoadingVps] = useState(false);
    const [vpsData, setVpsData] = useState({});
    const [modal, setModal] = useState(false);
    const [step, setStep] = useState(0);

    useEffect(()=>{
        document.title = `${translate('wingo')} - ${translate('menu_web_terminal')}`;

        //getServers();
        getServerTypes();
        setVpsData({...vpsData, image: "ubuntu-20.04"})
    },[]);

    /*useEffect(()=>{
        if(vpsData?.type){
            const find = serverTypes.find((x)=> x.id = vpsData.type);
            console.log(find)
        }
    },[vpsData])*/

    const getServers = async () => {
        await axios.get(`${Node_Api}/hetzner/get-servers`).then((result)=>{

        }).catch(()=>{

        })
    }

    const getServerTypes = async () => {
        setLoading(true)
        await axios.get(`${Node_Api}/hetzner/get-server-types`).then((result)=>{
            const rows = result.data.server_types.filter((x)=> (x.key = x.id));
            setServerTypes(rows);
            setLoading(false)
        }).catch(()=>{

        })
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            align: "center",
            render: (_, record) =>
                <>
                    <strong>{record.description}</strong>
                </>
        },
        {
            title: 'Memory (GB)',
            dataIndex: 'memory',
            key: 'memory',
            align: "center"
        },
        {
            title: 'Disk (GB)',
            dataIndex: 'disk',
            key: 'disk',
            align: "center"
        },
        {
            title: 'Storage Type',
            dataIndex: 'storage_type',
            key: 'storage_type',
            align: "center"
        },
        {
            title: 'CPU Type',
            dataIndex: 'cpu_type',
            key: 'cpu_type',
            align: "center"
        },
        {
            title: 'Cores',
            dataIndex: 'cores',
            key: 'cores',
            align: "center"
        },
        {
            title: 'location',
            dataIndex: 'location',
            render: (_,_value)=> <ServerTypes data={_value}/>
        }
    ];

    const ServerTypes = ({data}) => {
        const options = data?.prices.filter((x)=> (x.key = x.location, x.value = x.location));
        return (
            <Select
                key={data.id}
                value={vpsData?.server_type === data.name ? vpsData?.location : null}
                disabled={vpsData?.server_type != data.name}
                style={{width: "150px"}}
                options={options}
                placeholder={translate('select_option')}
                onChange={(x)=> setVpsData({...vpsData, location: x})}
            />
        )
    }

    const rowSelection = {

        onChange: (selectedRowKeys, selectedRows) => {
            let [row] = selectedRows;
            setVpsData({...vpsData, server_type: row.name, location: null})
            notification.info({
                description: "Please select your vps location"
            })
        }
    };

    const reviewServer = () => {
        setStep(1)
        setModal(true)
    }

    const createServer = () => {
        setLoadingVps(true)
        message.loading("Creating your VPS, Please wait!");

        const Now = Date.now();
        const data = {...vpsData,
            name: `vps-${user.id}-${vpsData.server_type}-${Now}`
        }
        console.log(data)

        //after done

        setTimeout(()=>{
            setVpsData({...vpsData, image: "ubuntu-20.04"})
            message.success("Created successfully!")
            setStep(2);
            setLoadingVps(false)
        },5000)
    }

    return (
        <PanelLayout>
            <div className={Style.Main}>
                <h1>{translate('menu_request_vps')}</h1>
                <VPSTabs/>

                <div className={`${Style.PanelBox} ${Style.PanelBoxPadding}`}>
                    <h3>{translate('vps_list')}</h3>
                    <Table
                        rowSelection={{
                            type: "radio",
                            ...rowSelection,
                        }}
                        loading={loading}
                        className={Style.LinksTable}
                        columns={columns}
                        dataSource={serverTypes}
                    />
                    <Button
                        disabled={!vpsData?.location || !vpsData?.server_type}
                        onClick={()=> reviewServer()}
                        shape="round"
                        size="large">
                        {translate('Create')}
                    </Button>
                </div>
            </div>
            <Modal
                centered
                closable={false}
                width={679}
                title={[
                    <div key={1} className={`${Style.AccountModalHeader} ${Style.AccountModalHeaderSmall}`}>
                        <span>{translate('menu_request_vps')}</span>
                    </div>
                ]}
                footer={[
                    <div key={1} className="d-flex justify-content-center" style={{ marginBottom: "30px" }}>
                        <Button
                            loading={loadingVps}
                            onClick={()=>createServer()}
                            className="dark-green-button-border dark-green-color"
                            size="large"
                            shape="round">
                            {!loadingVps ? translate('confirm') : translate('Creating')}
                        </Button>
                    </div>
                ]}
                open={modal}
            >
                {step === 1 ?
                <div>
                    <ul className={`${Style.DepositReview} list-unstyled m-0 p-0`}>
                        <li>
                            <span>{translate('vps_server_type')}</span>
                            <bdi>{vpsData?.server_type}</bdi>
                        </li>
                        <li>
                            <span>{translate('vps_server_location')}</span>
                            <bdi>{vpsData?.location}</bdi>
                        </li>
                    </ul>

                    <strong>Server Configs</strong>
                    <br/>
                    <br/>

                    <div className="d-flex justify-content-between align-items-center" style={{padding: "0 50px"}}>
                        <Statistic title="Memory (GB)" value={2} />
                        <Statistic title="Disk (GB)" value={20} />
                        <Statistic title="Cores" value={1} />
                    </div>

                </div>
                    : null
                }
            </Modal>
        </PanelLayout>
    )
}

export default CreateVps;