import PanelLayout from "../../../layout/panel";
import Style from "../../../assets/css/panel.module.css";
import FundsTabs from "../../../components/funds/tabs";
import {Button, Col, Input, message, notification, Row, Select, Skeleton} from "antd";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {
    getAccountApi,
    getAvailableWithdrawalConfigsForLoginSidApi,
    getPaymentDetailsByIdApi,
    payoutApi
} from "../../../api/api";
import Verified from "../../../assets/img/icon/check-verified-color.svg";
import {useNavigate} from "react-router-dom";
import History from "../../../components/funds/history";
import { PlusOutlined } from '@ant-design/icons';
import {useTranslation} from "react-i18next";
import {useWindowSize} from "../../../layout/components/windowSize";
import {Api} from "../../../api/config";
import Crypto from "../../../assets/img/icon/method/crypto.svg";
import Wallet from "../../../assets/img/icon/method/wallet.svg";
import CreditCard from "../../../assets/img/icon/method/credit-card.svg";
import Guides from "../../../components/guides";

const Index = () => {

    const token = useSelector((state) => state.auth?.token || null);
    const dir = useSelector((state) => state.auth?.setting?.dir || "ltr");
    const { t } = useTranslation();
    const translate = t;
    const navigate = useNavigate();
    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [withdraw, setWithdraw] = useState({});
    const [wallet, setWallet] = useState({});
    const [paymentDetails, setPaymentDetails] = useState([]);
    const [paymentDetailsSelected, setPaymentDetailsSelected] = useState({});
    const [paymentDetailsRequired, setPaymentDetailsRequired] = useState(false);
    const [step2AdditionalData, setStep2AdditionalData] = useState({});
    const [step2Data, setStep2Data] = useState({});
    const [step3Data, setStep3Data] = useState({});
    const {device} = useWindowSize();

    const [EmptyMethods, setEmptyMethods] = useState(false);

    const [CryptoMethods, setCryptoMethods] = useState([]);
    const [eWalletsMethods, setEWalletsMethods] = useState([]);
    const [DebitCardMethods, setDebitCardMethods] = useState([]);

    const [DisplayCryptoMethods, setDisplayCryptoMethods] = useState(true);
    const [DisplayEWalletsMethods, setDisplayEWalletsMethods] = useState(true);
    const [DisplayDebitCardMethods, setDisplayDebitCardMethods] = useState(true);
    const lang = useSelector((state) => state.auth?.setting?.lang || "en");

    useEffect(()=>{
        if(token){
            getAccounts();
        }
        document.title = `${translate('wingo')} - ${translate('menu_funds_withdraw')}`;
    },[translate]);

    const getAvailableWithdrawalConfigsForLoginSid = async (id) => {
        await getAvailableWithdrawalConfigsForLoginSidApi(id, token).then((result)=>{
            const methods = result.data;

            const array1 = methods?.filter(m => m.paymentDetailsConfigId === 24) || [];
            const array2 = methods?.filter(m => (m.paymentDetailsConfigId === 23 || m.paymentDetailsConfigId === 26)) || [];

            setDisplayCryptoMethods(array1.length ? true : false);
            setDisplayEWalletsMethods(array2.length ? true : false);
            setDisplayDebitCardMethods(false);

            setCryptoMethods(array1);
            setEWalletsMethods(array2);

            setEmptyMethods(!result.data.length ? true : false)

        }).catch((error)=>{
            console.log(error)
        })
    }

    const getAccounts = async () => {
        const data = {
            scope: "deposit"
        }

        setLoading(true)

        await getAccountApi(data, token).then((result)=>{
            const liveAccountsArray = [];

            result.data.map(item => {
                if (item.typeId === 1) {
                    liveAccountsArray.push(item);
                }
            });

            const walletAccount = liveAccountsArray?.filter(x => x.typeId === 1) || [];
            const w = walletAccount[0];
            handleWalletMethod(w?.login, w);
            setLoading(false)
        }).catch((error)=>{
            console.log(error)
        })
    }

    const handleWalletMethod = (id, value) => {
        setWallet(value)
        setWithdraw({...withdraw, wallet: id, loginSid: value.loginSid})
        getAvailableWithdrawalConfigsForLoginSid(value.loginSid)
    }

    const handleMethod = (Conf) => {
        setStep(1)
        setStep2AdditionalData({})
        setPaymentDetailsRequired(Conf.paymentDetailsRequired)
        getPaymentDetailsById(Conf.id)
        if(Conf.id === 2){
            setWithdraw({...withdraw, method: Conf, currency: Conf.displayName})
        } else {
            setWithdraw({...withdraw, method: Conf, currency: null})
        }
    }

    const withdrawRequest1 = async () => {
        setButtonLoading(true)

        if(wallet?.balance > 0){

            const data = {
                paymentSystem: withdraw.method.id,
                account: withdraw.loginSid,
                ...paymentDetailsRequired && {wallet: withdraw.paymentDetailsId}
            }

            await payoutApi(data, token).then((result)=>{

                if(result.data.step2AdditionalData){
                    setStep2AdditionalData({...result.data.step2AdditionalData, status: true});
                } else {
                    setStep2AdditionalData({status: true})
                }

                if(result.data.form.errors.length){
                    setButtonLoading(false)
                    message.error("Please try again later")
                } else {
                    const newData = convertedData(result.data.form.fields)
                    payoutApi({"form": newData}, token).then((result)=>{
                        if(result.data.form.errors.length){
                            setButtonLoading(false)
                            message.error("There is already one pending withdrawal for this account")
                        } else {
                            setStep3Data(result.data.form.fields)
                            setButtonLoading(false)
                            setStep(2)
                        }
                    }).catch((error)=> {
                        console.log(error)
                    })
                }

            }).catch((error)=> {
                setButtonLoading(false)
                notification.error({
                    description: "Error"
                })
                console.log(error)
            })
        } else {
            setButtonLoading(false)
            message.error("Not enough money")
        }
    }

    const withdrawRequest2 = async () => {
        setButtonLoading(true)
        const newData = convertedData(step3Data);
        await payoutApi({"form": newData}, token).then((result)=>{
            if(result.data.transactionId){
                setStep(3)
                setWithdraw({})
                setButtonLoading(false)
            } else {
                setButtonLoading(false)
                message.error("Pin is not valid")
            }
        }).catch((error)=> {
            setButtonLoading(false)
            notification.error({
                description: "Error"
            })
            console.log(error)
        })
    }

    const getPaymentDetailsById = async (id) => {
        setPaymentDetails([])
        await getPaymentDetailsByIdApi(id, token).then((result)=>{
            const modifiedPayments = result.data
                .filter((x) => x.status === "approved")
                .map((x) => ({ label: x.number, value: x.id }));
            setPaymentDetails(modifiedPayments)
        }).catch((error)=> {
            console.log(error)
        })
    }

    const convertedData = (fields) => fields.reduce((acc, item) => {

        if (item.name === 'amount') {
            acc.amount = withdraw?.amount
        } else if (item.name === 'calculatedAmount') {
            acc.calculatedAmount = withdraw?.amount
        } else if (item.name === 'pin') {
            acc.pin = withdraw?.pin
        } else {
            acc[item.name] = item.value;
        }

        return acc;
    }, {});

    const getCreditedAmount = (x) => {
        const baseFee = x * 0.002;
        const fee = Math.max(baseFee, 1);
        const result = Math.min(fee, 5);
        return Math.abs(x - result);
    }

    const MethodTemplate = ({methods, title, icon}) => {
        return (
            <>
                <div className={Style.PanelBox} style={{marginBottom: "30px"}}>
                    <div className={Style.PanelWrapperPadding} style={{paddingTop: "45px"}}>
                        <div className={Style.DepositMethodIcon}>
                            <img style={dir === "ltr" ? {marginRight: "10px"} : {marginLeft: "10px"}} src={icon}/>
                            <h3>{title}</h3>
                        </div>
                        <div style={{marginTop: "30px"}}>
                            <ul className={`${Style.DepositHead} d-flex justify-content-between list-unstyled m-0 p-0`}>
                                <li><span>{translate('transfer_method')}</span></li>
                                <li><span>{translate('processing_time')}</span></li>
                                <li><span>{translate('min_max_dep')}</span></li>
                                <li><span>{translate('fee_commission')}</span></li>
                                <li><span></span></li>
                            </ul>
                            {methods?.length ?
                                <ul className={`${Style.DepositDetailsItems} ${Style.DepositMethodItem}`}>
                                    {methods.map((Method, Index)=>{
                                        return (
                                            <li key={Index}>
                                                <div className={`${Style.DepositItem} ${Style.DepositItemList}`}>
                                                    <img width={50} src={Api + Method.logo} />
                                                    <h5>{Method.displayName}</h5>
                                                </div>
                                                <div className={Style.DepositItemList}>
                                                    <bdi>{translate('processing_time')}</bdi>
                                                    <span>{translate('up_24')}</span>
                                                </div>
                                                <div className={Style.DepositItemList}>
                                                    <bdi>{translate('min_max_dep')}</bdi>
                                                    <span>{translate('min')}: <strong style={{marginLeft: "5px", display: "inline-block"}}>$10</strong></span>
                                                    <span>{translate('max')}: {translate('unlimited')}</span>
                                                </div>
                                                <div className={Style.DepositItemList}>
                                                    <bdi>{translate('fee_commission')}</bdi>
                                                    <span>0,2%</span>
                                                </div>
                                                <div className={Style.DepositItemList}>
                                                    <Button className="dark-green-button-border dark-green-color" shape="round" onClick={() => handleMethod(Method)}>{translate('menu_funds_withdraw')}</Button>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul> :
                                <Skeleton/>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <PanelLayout>
            <div className={Style.Main}>
                <h1>{translate('menu_my_funds')}</h1>
                <Guides type="withdraw" locale={lang}/>

                <FundsTabs/>
                <h2>{translate('menu_funds_withdraw')}</h2>

                {step === 0 ?
                    !EmptyMethods ?
                        <>
                            {DisplayCryptoMethods ? <MethodTemplate methods={CryptoMethods} title="Crypto" icon={Crypto}/> : null}
                            {DisplayEWalletsMethods ? <MethodTemplate methods={eWalletsMethods} title="e-wallets" icon={Wallet}/> : null}
                            {DisplayDebitCardMethods ? <MethodTemplate methods={DebitCardMethods} title="Debit Card" icon={CreditCard}/> : null}
                        </>
                        :
                        <div className={Style.PanelBox}>
                            <div className={Style.PanelWrapperPadding}>
                                <p>{translate('funds_payment_details_message')}</p>
                            </div>
                        </div>
                    :
                    <div className={Style.PanelBox}>
                        {step === 3 ?
                            <div className={`${Style.WithdrawSuccess} d-flex align-items-center justify-content-between`}>
                                <div className="w-100 text-center d-flex align-items-center justify-content-center">
                                    <img width={47} height={47} src={Verified}/>
                                    <span>{translate('withdraw_message')}</span>
                                </div>
                            </div> : null
                        }

                        <div className={Style.PanelWrapperPadding}>

                            {step === 1 ?
                                <>
                                    <div style={{marginBottom: "50px"}}>
                                        <div className={`${Style.DepositItem} ${Style.DepositItemList}`}>
                                            <img width={50} src={Api + withdraw?.method?.logo} />
                                            <h5>{withdraw?.method?.displayName}</h5>
                                        </div>
                                    </div>
                                    <Row gutter={50} className={Style.DepositMethodForm}>
                                        <Col md={12} className="ant-col-full">
                                            <div className="d-md-flex" style={{ marginBottom: "27px" }}>
                                                <label className="text-nowrap">{translate('funds_withdraw_amount')}</label>
                                                <div className="w-100">
                                                    <Input type="number" className="deposit-select" value={withdraw?.amount} suffix="$" onChange={(e) => setWithdraw({ ...withdraw, amount: e.target.value })} />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={12} className="ant-col-full">
                                            <div className="d-md-flex">
                                                <label className="text-nowrap">{translate('funds_credited_amount')}</label>
                                                <div className="w-100">
                                                    <Input className="deposit-select" readOnly value={getCreditedAmount(withdraw?.amount) || 0} suffix="$" />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col span={24}>
                                            {paymentDetailsRequired ?
                                                <div className={`${Style.DepositMethods} d-md-flex`} style={{ marginTop: "40px" }}>
                                                    <label className="text-nowrap gray-color">{translate('menu_funds_payment_details')}</label>
                                                    <Select
                                                        disabled={!paymentDetails.length}
                                                        loading={!paymentDetails.length ? true : false}
                                                        placeholder={translate('select_option')}
                                                        className="w-100 deposit-select"
                                                        options={paymentDetails}
                                                        value={withdraw?.paymentDetailsId}
                                                        onChange={(choice,_value) => (setWithdraw({...withdraw, paymentDetailsId: choice}), setPaymentDetailsSelected(_value))}
                                                    />
                                                    <Button
                                                        icon={<PlusOutlined />}
                                                        onClick={() => navigate("/funds/details")}
                                                        className="dark-green-color"
                                                        shape="round"
                                                        style={{
                                                            marginTop: device === "mobile" ? "10px" : null,
                                                            marginRight: dir === "rtl" ? "10px" : null,
                                                            marginLeft: dir === "ltr" ? "10px" : null
                                                        }}
                                                    >
                                                        {translate('payment_details_upload')}
                                                    </Button>
                                                </div> : null}
                                            {!paymentDetails.length ? <p style={{color: "red"}}>{translate('funds_payment_details_message')}</p> : null}
                                        </Col>
                                    </Row>

                                    <div className={Style.DepositDetails}>
                                        <h3>{translate('withdraw_details')}</h3>
                                        <ul className={`${Style.DepositHead} d-flex justify-content-between list-unstyled m-0 p-0`}>
                                            <li><span>{translate('funds_withdraw_from')}</span></li>
                                            <li><span>{translate('withdraw_method')}</span></li>
                                            <li><span>{translate('processing_time')}</span></li>
                                            <li><span>{translate('fee_commission')}</span></li>
                                            <li><span>{translate('fees')}</span></li>
                                        </ul>

                                        <ul className={`${Style.DepositDetailsItems} p-0 d-md-flex`} style={{margin: "20px 0 0 0"}}>
                                            <li className={Style.DepositItemList}>
                                                <bdi>{translate('funds_withdraw_from')}</bdi>
                                                <span>{withdraw?.walletType} {withdraw?.loginSid}</span>
                                            </li>
                                            <li className={Style.DepositItemList}>
                                                <bdi>{translate('withdraw_method')}</bdi>
                                                <span>{withdraw?.method?.displayName}</span>
                                            </li>
                                            <li className={Style.DepositItemList}>
                                                <bdi>{translate('processing_time')}</bdi>
                                                <span>{translate('up_24')}</span>
                                            </li>
                                            <li className={Style.DepositItemList}>
                                                <bdi>{translate('fee_commission')}</bdi>
                                                <span>{translate('no_commission')}</span>
                                            </li>
                                            <li className={Style.DepositItemList}>
                                                <bdi>{translate('fees')}</bdi>
                                                <span>0.2%</span>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className={`${Style.DepositMethod} ${Style.DepositNotes}`}>
                                        <h5>
                                            <span>{translate('commission_fee')}</span>
                                        </h5>

                                        <p>{translate('blockchain_fee')}: 0.2%</p>
                                        <p>{translate('withdraw_message_fee')}</p>
                                        <br />
                                        <br />
                                        <h6>{translate('withdraw_message_fee2')}</h6>
                                    </div>
                                </>
                                : step === 2 ?
                                    <>
                                        <ul className={`${Style.DepositReview} list-unstyled m-0 p-0`}>
                                            <li>
                                                <span>{translate('funds_withdraw_with')}</span>
                                                <bdi>{withdraw?.method?.displayName}</bdi>
                                            </li>
                                            <li>
                                                <span>{translate('funds_withdraw_from')}</span>
                                                <bdi>Wallet {withdraw?.wallet}</bdi>
                                            </li>
                                            <li>
                                                <span>{translate('funds_withdraw_amount')}</span>
                                                <bdi>{withdraw?.amount} USD</bdi>
                                            </li>
                                            <li>
                                                <span>{translate('funds_amount_credited')}</span>
                                                <bdi>{getCreditedAmount(withdraw?.amount)} USD</bdi>
                                            </li>
                                            <li>
                                                <span>{translate('menu_funds_payment_details')}</span>
                                                <bdi>{paymentDetailsSelected?.label}</bdi>
                                            </li>
                                        </ul>
                                        <Row>
                                            <Col md={10} className={`${Style.DepositReview} ant-col-full`}>
                                                <strong>{translate('funds_enter_pin')}</strong>
                                            </Col>
                                            <Col md={14} className="ant-col-full">
                                                <Input className="w-100" style={device != "mobile" ? {borderRadius: "25px", marginLeft: dir === "ltr" ? "20px" : null, marginRight: dir === "rtl" ? "20px" : null} : {marginTop: "20px", borderRadius: "25px"}} onChange={(e)=> setWithdraw({...withdraw, pin: e.target.value})} />
                                            </Col>
                                        </Row>
                                        <div className={`${Style.DepositReview} d-md-flex`}>
                                        </div>
                                    </>
                                    : step === 3 ?
                                        <>
                                            <History type="pending" id={1} />
                                        </>
                                        : null
                            }


                            <div className={`d-flex justify-content-end ${Style.DepositMethodButtons}`}>
                                {step > 0 && step < 4 ? <Button shape="round" className="black-color" onClick={()=>(setStep(step === 1 ? 0 : step === 2 ? 1 : step === 3 ? 2 : step === 4 ? 3 : 0), step === 1 ? setWithdraw({}) : null)}>{translate('back')}</Button> : null}
                                {step < 4 ?
                                    <Button
                                        loading={buttonLoading}
                                        shape="round"
                                        className="dark-green-button white-color"
                                        disabled={step === 1 ? !withdraw?.amount || (paymentDetailsRequired && !withdraw?.paymentDetailsId) : step === 3 ? !withdraw?.pin : false}
                                        onClick={()=> (step === 1 ? withdrawRequest1() : step === 2 ? withdrawRequest2() : null)}
                                    >
                                        {step === 2 ? translate('confirm') : translate('continue')}
                                    </Button> : null}
                            </div>
                        </div>

                    </div>
                }
            </div>
        </PanelLayout>
    )
}

export default Index;