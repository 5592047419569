import AuthLayout from "../../layout/auth";
import {Button, Checkbox, Col, Input, message, notification, Row, Select} from "antd";
import {
    confirmRegistrationApi, getProfileApi,
    getRegistrationFormConfigApi,
    registerClientApi,
} from "../../api/api";
import {useEffect, useState} from "react";
import {countriesCode} from "../../api/data";
import Style from "../../assets/css/panel.module.css";
import Logo from "../../assets/img/auth/reg-splash.svg";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import Password from "antd/es/input/Password";
import {setReferral, setToken, setUserData} from "../../redux/actions/user";
import {useWindowSize} from "../../layout/components/windowSize";
import Guides from "../../components/guides";

const Register = () => {

    const dispatch = useDispatch();
    const setting = useSelector((state) => state.auth?.setting || null);
    const referral = useSelector((state) => state.auth?.referral || {});
    const { t } = useTranslation();
    const translate = t;
    const lang = setting?.lang || "en";
    const dir = setting?.dir || "ltr";
    const navigate = useNavigate();
    const [config, setConfig] = useState({});
    const [phoneCodes, setPhoneCodes] = useState([]);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [registerForm, setRegisterForm] = useState({});
    const [birthday, setBirthday] = useState({});
    const [phone, setPhone] = useState({});
    const [checkboxes, setCheckboxes] = useState({});
    const [allowRegister, setAllowRegister] = useState(false);
    const [pin, setPin] = useState(null);
    const [step, setStep] = useState(1);
    const [userId, setUserId] = useState();
    const {device} = useWindowSize();

    const newDocuments = [
        {
            title: "Terms and Conditions",
            link: "https://client.wingomarkets.com/uploads/public/company-documents/Wingo-Markets-Terms-and-Conditions.pdf"
        },
        {
            title: "Privacy Policy",
            link: "https://client.wingomarkets.com/uploads/public/company-documents/Wingo-Markets-Privacy-Policy.pdf"
        },
        {
            title: "AML/KYC",
            link: "https://client.wingomarkets.com/uploads/public/company-documents/Wingo-Markets-KYC-AML-Policy.pdf"
        },
        {
            title: "Risk Disclosure",
            link: "https://client.wingomarkets.com/uploads/public/company-documents/Wingo-Markets-Risk-Warning-Notice.pdf"
        }
    ]

    useEffect(()=>{
        getRegistrationFormConfig()
        const codes = countriesCode;
        codes.map((x, i)=>x.key = i + 1)
        codes.filter((x)=>(x.label = `${x.iso} (+${x.value})`))
        setPhoneCodes(codes)

        document.title = `${translate('wingo')} - ${translate('register')}`;
    },[])

    useEffect(() => {
        const sum = Object.values(checkboxes).reduce((acc, currentValue) => acc + currentValue, 0);
        if(sum === 4){
            setAllowRegister(true)
        } else {
            setAllowRegister(false)
        }

    }, [checkboxes]);

    const getRegistrationFormConfig = async () => {

        await getRegistrationFormConfigApi().then((result)=>{

            const days = result.data.properties.birthDate.properties.day.options.enum_titles;
            const months = result.data.properties.birthDate.properties.month;
            const years = result.data.properties.birthDate.properties.year.options.enum_titles;
            const country = result.data.properties.country.options.enum_titles;
            const countryCode = result.data.properties.country.enum;

            const daysObj = days.map((x)=> (
                {
                    key: x,
                    value: x,
                    label: x
                }
            ));
            const monthsObj = months?.enum_titles.map((x, i)=> (
                {
                    key: x,
                    value: i+1,
                    label: x
                }
            ));
            const yearsObj = years.map((x)=> (
                {
                    key: x,
                    value: x,
                    label: x
                }
            ));
            const countryObj = country.map((x, index)=>{
                const code = countryCode[index];
                return (
                    {
                        key: x,
                        value: code,
                        label: x
                    }
                )
            })

            setConfig(
                {...config,
                    days: daysObj,
                    months: monthsObj,
                    years: yearsObj,
                    country: countryObj,
                    documents: [
                        result.data.properties.document_1,
                        result.data.properties.document_2,
                        result.data.properties.document_3,
                        result.data.properties.document_4
                    ]
                }
            )

        })
    }

    const registerClient = async () => {
        setButtonLoading(true)
        const bday = (`${birthday.year}-${birthday.month}-${birthday.day}`);
        const data = {...registerForm,
            birthDate: bday,
            clientType: "Individual",
            language: lang,
            phone: `+${phone.code}${phone.phone}`,
            ...referral?.partnerId && {partnerId: referral.partnerId},
            ...referral?.referralLinkId && {referralLinkId: referral.referralLinkId}
        }

        await registerClientApi(data).then((result)=>{
            setButtonLoading(false)
            notification.success({description: translate('error_register')})
            message.success(translate('pin_sent'))
            setUserId(result.data.id);
            setStep(2)
            dispatch(setReferral(null))
        }).catch((error)=> {
            setButtonLoading(false)

            const err = error?.response?.data?.errors?.children;
            Object.keys(err).forEach((key) => {
                const errorObject = err[key];

                if (errorObject && errorObject.errors && errorObject.errors.length > 0) {
                    errorObject.errors.forEach((error) => {
                        notification.error({description: error})
                    });
                }
            });
        })
    }

    const confirmRegistration = async () => {
        setButtonLoading(true)
        const data = {
            userId: userId,
            pin: pin
        }
        await confirmRegistrationApi(data).then((result)=>{
            const token = result?.data?.data?.token;

            message.success(translate('confirm_done'))
            setButtonLoading(false)
            dispatch(setToken(token))
            navigate('/dashboard');
            getProfile(token)
        }).catch((error)=>{
            console.log(error)
            message.error(translate('confirm_wrong'))
            setButtonLoading(false)
        })
    }

    const getProfile = async (token) => {
        await getProfileApi(token).then((result)=>{
            dispatch(setUserData(result?.data))
        }).catch((error)=>{
            console.log(error)
        })
    }

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <AuthLayout>
            <div className="mh-100 d-md-flex">
                <div className={Style.AuthSider40}>
                    <div className={Style.AuthRegSplash}>
                        <div>
                            <img src={Logo} width={Logo.width} height={Logo.height}/>
                        </div>
                    </div>
                </div>
                <div className={Style.AuthSider60}>
                    <div className={Style.AuthForm}>
                        {step === 1 ?
                            <div className="w-100">
                                <div>
                                    <h6>{translate('registration_details')}</h6>
                                    <Guides type="register" locale={lang}/>
                                </div>

                                <div>
                                    <Row gutter={10} style={device === "mobile" ? {marginBottom: "10px"} : {marginBottom: "20px"}}>
                                        <Col md={12} className="ant-col-full" style={device === "mobile" ? {marginBottom: "10px"} : null}>
                                            <Input
                                                placeholder={translate('name')}
                                                style={{borderRadius: '25px'}}
                                                onChange={(e)=> setRegisterForm({...registerForm, firstName: e.target.value})}
                                            />
                                        </Col>
                                        <Col md={12} className="ant-col-full">
                                            <Input
                                                placeholder={translate('profile_last_name')}
                                                style={{borderRadius: '25px'}}
                                                onChange={(e)=> setRegisterForm({...registerForm, lastName: e.target.value})}
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={10} style={{marginBottom: "20px"}}>
                                        <Col span={8}>
                                            <Select
                                                filterOption={filterOption}
                                                showSearch={true}
                                                className="w-100"
                                                placeholder={translate('birthday_year')}
                                                options={config.years}
                                                onChange={(ch)=> setBirthday({...birthday, year: ch})}
                                            />
                                        </Col>
                                        <Col span={8}>
                                            <Select
                                                className="w-100"
                                                placeholder={translate('birthday_month')}
                                                options={config.months}
                                                onChange={(ch)=> setBirthday({...birthday, month: ch})}
                                            />
                                        </Col>
                                        <Col span={8}>
                                            <Select
                                                className="w-100"
                                                placeholder={translate('birthday_day')}
                                                options={config.days}
                                                onChange={(ch)=> setBirthday({...birthday, day: ch})}
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={10} style={{marginBottom: "20px"}}>
                                        <Col md={12} className="ant-col-full" style={device === "mobile" ? {marginBottom: "10px"} : null}>
                                            <Select
                                                className="w-100"
                                                showSearch={true}
                                                placeholder={translate('profile_country')}
                                                options={config.country}
                                                filterOption={filterOption}
                                                onChange={(ch)=> setRegisterForm({...registerForm, country: ch})}
                                            />
                                        </Col>
                                        <Col md={12} className="ant-col-full">
                                            <Input
                                                type="number"
                                                addonBefore={dir === "rtl" ? null :
                                                    <Select
                                                        style={{width: "110px"}}
                                                        showSearch={true}
                                                        options={phoneCodes}
                                                        placeholder={translate('code')}
                                                        optionFilterProp="children"
                                                        filterOption={filterOption}
                                                        onChange={(ch) => setPhone({ ...phone, code: ch })}
                                                    />}
                                                addonAfter={dir === "ltr" ? null :
                                                    <Select
                                                        style={{width: "110px"}}
                                                        showSearch={true}
                                                        options={phoneCodes}
                                                        placeholder={translate('code')}
                                                        optionFilterProp="children"
                                                        filterOption={filterOption}
                                                        onChange={(ch) => setPhone({ ...phone, code: ch })}
                                                    />}
                                                onChange={(e)=> setPhone({...phone, phone: e.target.value})}
                                                placeholder={translate('phone_number')}
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={10} style={{marginBottom: "20px"}}>
                                        <Col md={12} className="ant-col-full" style={device === "mobile" ? {marginBottom: "10px"} : null}>
                                            <Input
                                                placeholder={translate('profile_email')}
                                                style={{borderRadius: '25px'}}
                                                onChange={(e)=> setRegisterForm({...registerForm, email: e.target.value})}
                                            />
                                        </Col>
                                        <Col md={12} className="ant-col-full">
                                            <Password
                                                type="password"
                                                placeholder={translate('profile_password')}
                                                style={{borderRadius: '25px'}}
                                                onChange={(e)=> setRegisterForm({...registerForm, password: e.target.value})}
                                            />
                                        </Col>
                                    </Row>
                                </div>

                                <div className={Style.AuthTerms}>
                                    {newDocuments?.map((Doc, Index)=>{
                                        return (
                                            <div key={Index} className="d-flex" style={{marginBottom: "20px"}}>
                                                <Checkbox
                                                    style={dir === "ltr" ? {marginRight: "15px"} : {marginLeft: "15px"}}
                                                    name={Doc.fieldType}
                                                    onChange={(e)=>setCheckboxes({...checkboxes, [Index]: e.target.checked})}
                                                />
                                                <span>I confirm I've read, understand and agree to the <Link target="_blank" to={Doc.link}>{Doc.title}</Link></span>
                                            </div>
                                        )
                                    })}
                                </div>

                                <div style={{marginBottom: "20px"}}>
                                    <Button
                                        style={{marginTop: "35px"}}
                                        shape="round"
                                        size="large"
                                        className="dark-green-button white-color"
                                        loading={buttonLoading}
                                        onClick={()=>registerClient()}
                                        disabled={!allowRegister
                                            || !registerForm?.firstName
                                            || !registerForm?.lastName
                                            || !registerForm?.lastName
                                            || !birthday?.year
                                            || !birthday?.month
                                            || !birthday?.day
                                            || !registerForm?.country
                                            || !phone?.code
                                            || !phone?.phone
                                            || !registerForm?.email
                                            || !registerForm?.password
                                        }
                                    >
                                        {translate('continue')}
                                    </Button>
                                    <Button onClick={()=> navigate("/login")} style={dir === "ltr" ? {marginLeft: "10px"} : {marginRight: "10px"}} size="large" shape="round">{translate('already_account')}</Button>
                                </div>
                            </div>
                            : step === 2 ?
                                <div className="w-100">
                                    <div style={{marginBottom: "20px"}}>
                                        <h6>{translate('funds_confirmation')}</h6>
                                        <small>{translate('confirm_text')}</small>

                                        <div style={{marginTop: "20px"}}>
                                            <Input
                                                placeholder={translate('pin')}
                                                style={{borderRadius: '25px'}}
                                                onChange={(e)=> setPin(e.target.value)}
                                            />
                                        </div>

                                        <Button
                                            style={{marginTop: "35px"}}
                                            shape="round"
                                            size="large"
                                            className="dark-green-button white-color"
                                            loading={buttonLoading}
                                            onClick={()=>confirmRegistration()}
                                            disabled={!pin}
                                        >
                                            {translate('continue')}
                                        </Button>
                                        <Button onClick={()=> setStep(1)} style={dir === "ltr" ? {marginLeft: "10px"} : {marginRight: "10px"}} size="large" shape="round">{translate('back')}</Button>
                                    </div>
                                </div>
                                : null}
                    </div>
                </div>
            </div>

        </AuthLayout>
    )
}

export default Register;