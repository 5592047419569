import {
    changeLeverageApi,
    changePasswordApi,
    getAccountDetailsApi,
    sendPinApi,
    sendReportApi
} from "../../api/api";
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Button, Col, Input, message, Modal, notification, Row, Select, Steps, Switch} from "antd";
import {leverage} from "../../api/data";
import PanelLayout from "../../layout/panel";
import Style from "../../assets/css/panel.module.css";
import AccountsTabs from "../../components/accounts/tabs";
import Copy from "../../assets/img/icon/copy.svg";
import { EditOutlined } from '@ant-design/icons';
import TransferIcon from "../../assets/img/icon/transfer-icon.svg";
import GridIcon from "../../assets/img/icon/grid-icon.svg";
import ChartIcon from "../../assets/img/icon/chart-icon.svg";
import {useTranslation} from "react-i18next";
import Guides from "../../components/guides";

const Account = () => {

    const navigate = useNavigate();
    const { account_id } = useParams();
    const token = useSelector((state) => state.auth?.token || null);
    const dir = useSelector((state) => state.auth?.setting?.dir || null);
    const { t } = useTranslation();
    const translate = t;
    const [account, setAccount] = useState();
    const [passwordData, setPasswordData] = useState({});
    const [leverageData, setLeverageData] = useState({});
    const [switchToggle, setSwitchToggle] = useState(false);
    const [modal, setModal] = useState({});
    const [buttonLoading, setButtonLoading] = useState(false);
    const [step, setStep] = useState(1);
    const lang = useSelector((state) => state.auth?.setting?.lang || "en");

    useEffect(()=>{
        if(account_id){
            getAccountById();
            setPasswordData({loginSid: account_id})
        }
        document.title = `${translate('wingo')} - ${translate('funds_account')} ${account_id}`;
    },[]);



    const getAccountById = async () => {
        await getAccountDetailsApi(account_id, token).then((result)=>{
            setAccount(result.data);
            setLeverageData({...leverageData, loginSid: account_id, leverage: result.data.leverage})
            setSwitchToggle(result.data?.sendReports);
        }).catch((error)=> {
            console.log(error)
        })
    }

    const changePassword = async () => {

        const { confirm, ...passwordDataWithoutConfirm } = passwordData;
        const data = { ...passwordDataWithoutConfirm };

        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&]{8,}$/;
        const passwordCheck = passwordRegex.test(data?.password);

        setButtonLoading(true);

        if(passwordCheck){
                await changePasswordApi(data, token).then(()=>{
                message.success(translate('error_password'))
                setPasswordData({loginSid: account_id})
                setModal({...modal, status: false})
                setButtonLoading(false)
            }).catch((error)=>{
                setButtonLoading(false)
                message.error(error.response.data.message)
            })
        } else {
            setButtonLoading(false)
            message.error("Password must contain at least 1 upper case Latin letter, 1 lower case Latin letter, 1 digit, 1 special character and be at least 8 characters long")
        }
    }

    const changeLeverage = async () => {
        setButtonLoading(true)
        await changeLeverageApi(leverageData, token).then(()=>{
            message.success(translate('error_leverage'))
            getAccountById()
            setButtonLoading(false)
            setModal({...modal, status: false})
        }).catch((error)=> {
            setButtonLoading(false)
            console.log(error)
        })
    }

    const changeReport = async (status) => {
        setSwitchToggle(status)
        const data = {
            loginSid: account_id,
            sendReport: status
        }
        await sendReportApi(data, token).then(()=>{
            message.success(translate('error_report'))
        })
    }

    const sendPin = async () => {
        setButtonLoading(true)
        const data = {
            action: modal?.type === "investor" ? "changeAccountPassword" : "changeAccountPassword",
            method: "email"
        }
        await sendPinApi(data, token).then(()=>{
            message.success(translate('error_2fa_sent'))
            setStep(2)
            setButtonLoading(false)
        }).catch((error)=>{
            setButtonLoading(false)
            const message = error?.response?.data?.message;
            notification.error({
                message: message
            })
        })
    }

    const handleCopy = (v) => {
        message.success(translate('copied'))
        navigator.clipboard.writeText(v)
    }

    const handleModalAction = (t) => {
        if(t === "leverage"){
            changeLeverage()
        } else if(t === "investor"){
            if(step === 1){
                sendPin()
                setPasswordData({...passwordData, passwordType: "investor"})
            } else if(step === 2){
                changePassword()
            }
        } else if(t === "platform"){
            if(step === 1){
                sendPin()
                setPasswordData({...passwordData, passwordType: "main"})
            } else if(step === 2){
                changePassword()
            }
        }
    }

    return (
        <PanelLayout>
            <div className={Style.Main}>
                <h1>{translate('menu_my_accounts')}</h1>
                <Guides type="changePassword" locale={lang}/>
                <AccountsTabs />

                <div style={{ marginTop: "47px" }} className={`${Style.PanelBox} ${Style.PanelBoxPadding} ${Style.WalletList}`}>

                    <Row gutter={{ xxl: 80, xl: 40, lg: 20, md: 20 }}>
                        <Col md={12} className="ant-col-full">
                            <div className={Style.AccountTable}>
                                <ul className="list-unstyled m-0 p-0">
                                    <li>
                                        <span>{translate('account_type')}</span>
                                        <bdi>{account?.type?.title}</bdi>
                                        <div onClick={() => handleCopy(account?.type?.title)} className={`${Style.CopyButton} cursor-pointer`}>
                                            <img src={Copy} width={18} height={18} alt="Copy" />
                                        </div>
                                    </li>
                                    {account?.accountConfig?.showServer &&
                                        <li>
                                            <span>{translate('account_server')}</span>
                                            <bdi>{account?.type?.server}</bdi>
                                            <div onClick={() => handleCopy(account?.type?.server)} className={`${Style.CopyButton} cursor-pointer`}>
                                                <img src={Copy} width={18} height={18} alt="Copy" />
                                            </div>
                                        </li>
                                    }
                                    <li>
                                        <span>{translate('account_created_at')}</span>
                                        <bdi>{account?.createdAt}</bdi>
                                        <div></div>
                                    </li>
                                    <li>
                                        <span>{translate('account_trading_platform_login')}</span>
                                        <bdi>{account?.login}</bdi>
                                        <div onClick={() => handleCopy(account?.login)} className={`${Style.CopyButton} cursor-pointer`}>
                                            <img src={Copy} width={18} height={18} alt="Copy" />
                                        </div>
                                    </li>
                                    {account?.accountConfig?.showPassword &&
                                        <li>
                                            <span>{translate('account_trading_platform_password')}</span>
                                            <bdi>******</bdi>
                                            <div style={{ width: "100px" }} className={Style.ChangeButton}>
                                                <Button onClick={() => (setModal({ status: true, type: "platform" }), setStep(1))} className="dark-green-button-border dark-green-color" icon={<EditOutlined />}>
                                                    <strong>{translate('change')}</strong>
                                                </Button>
                                            </div>
                                        </li>
                                    }
                                    {account?.accountConfig?.showPassword &&
                                        <li>
                                            <span>{translate('account_investor_password')}</span>
                                            <bdi>******</bdi>
                                            <div style={{ width: "100px" }} className={Style.ChangeButton}>
                                                <Button onClick={() => (setModal({ status: true, type: "investor" }), setStep(1))} className="dark-green-button-border dark-green-color" icon={<EditOutlined />}>
                                                    <strong>{translate('change')}</strong>
                                                </Button>
                                            </div>
                                        </li>
                                    }
                                    {account?.accountConfig?.showLeverage &&
                                        <li>
                                            <span>{translate('funds_leverage')}</span>
                                            <bdi>{account?.leverage}</bdi>
                                            <div style={{ width: "100px" }} className={Style.ChangeButton}>
                                                <Button onClick={() => (setModal({ status: true, type: "leverage" }), setStep(1))} className="dark-green-button-border dark-green-color" icon={<EditOutlined />}>
                                                    <strong>{translate('change')}</strong>
                                                </Button>
                                            </div>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </Col>
                        <Col md={12} className="ant-col-full">
                            <div className={Style.AccountTable}>
                                <ul className="list-unstyled m-0 p-0">
                                    <li>
                                        <span>{translate('account_margin')}</span>
                                        <bdi>{account?.margin}</bdi>
                                        <div></div>
                                    </li>
                                    <li>
                                        <span>{translate('account_free_margin')}</span>
                                        <bdi>{account?.marginFree}</bdi>
                                        <div></div>
                                    </li>
                                    <li>
                                        <span>{translate('account_margin_level')}</span>
                                        <bdi>{account?.marginLevel}</bdi>
                                        <div></div>
                                    </li>
                                    <li>
                                        <span>{translate('account_maximum_orders')}</span>
                                        <bdi>0</bdi>
                                        <div></div>
                                    </li>
                                    <li>
                                        <span>{translate('account_send_reports')}</span>
                                        <div className={Style.ChangeButton}>
                                            <Switch
                                                checked={switchToggle}
                                                checkedChildren={translate('yes')} unCheckedChildren={translate('no')}
                                                onChange={(e) => changeReport(e)}
                                            />
                                        </div>
                                        <bdi></bdi>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>

                    <div className={Style.AccountsOverviewDetails}>
                        <Row>
                            <Col span={6}>
                                <span>{translate('funds_balance')}</span>
                                <bdi>{account?.balance}</bdi>
                            </Col>
                            <Col span={6}>
                                <span>{translate('account_credit')}</span>
                                <bdi>{account?.credit}</bdi>
                            </Col>
                            <Col span={6}>
                                <span>{translate('account_equity')}</span>
                                <bdi>{account?.equity}</bdi>
                            </Col>
                            <Col span={6}>
                                <span>{translate('account_floating_pl')}</span>
                                <bdi>0</bdi>
                            </Col>
                        </Row>
                    </div>

                    <div style={{ marginTop: "40px" }} className="d-flex justify-content-end">
                        <Button size="large" onClick={() => navigate("/accounts/overview")} shape="round" style={{ marginRight: "25px" }}>{translate('back')}</Button>
                        {account?.type?.id !== 7 &&
                            <Button size="large" onClick={() => navigate(`/funds/transfer/${account_id}`)} className="d-flex" icon={<img width={24} height={24} src={TransferIcon} />} shape="round" style={{ marginRight: "25px" }}>
                                {translate('account_internal_transfer')}
                            </Button>
                        }
                        <Button size="large" onClick={() => navigate(`/accounts/trading/${account_id}`)} className="d-flex" icon={<img width={24} height={24} src={GridIcon} />} shape="round" style={{ marginRight: "25px" }}>
                            {translate('account_trading_history')}
                        </Button>
                        <Button size="large" onClick={() => navigate(`/accounts/stats/${account_id}`)} className="d-flex" icon={<img width={24} height={24} src={ChartIcon} />} shape="round" style={{ marginRight: "25px" }}>
                            {translate('account_stats')}
                        </Button>
                    </div>
                </div>
            </div>

            <Modal
                className={dir === "rtl" ? `${Style.RtlStyle} rtl` : null}
                closable={false}
                width={679}
                centered
                open={modal?.status}
                onCancel={() => (setModal({ ...modal, status: false }), setStep(1))}
                title={[
                    <div key={1}>
                        <div className={`${Style.AccountModalHeader} ${Style.AccountModalHeaderSmall}`}>
                            {modal?.type === "platform" ?
                                <span>{translate('change_trading_platform_password')}</span>
                                : modal?.type === "investor" ?
                                    <span>{translate('change_investor_password')}</span>
                                    : modal?.type === "leverage" ?
                                        <span>{translate('change_leverage')}</span>
                                        : null}
                        </div>
                        <Steps className="account-steps ant-steps-item-accounts" current={step - 1} items={[{ title: translate('send_pin'), content: 'a' }, { title: translate('modal_title_change_password'), content: 'b' }]} />
                    </div>
                ]}
                footer={[
                    <div key={1} className="d-flex justify-content-center" style={{ marginBottom: "30px" }}>
                        <Button size="large" shape="round" onClick={() => step === 1 ? (setModal({ ...modal, status: false }), setButtonLoading(false)) : step === 2 ? setStep(1) : null}>
                            {step === 1 ? translate('cancel') : step === 2 ? translate('back') : null}
                        </Button>
                        <Button size="large" disabled={step === 2 ? !passwordData?.pin || !passwordData?.password || !passwordData?.confirm || (passwordData?.password != passwordData?.confirm) : false} loading={buttonLoading} shape="round" className="dark-green-button white-color" onClick={() => handleModalAction(modal?.type)}>
                            {step === 1 ? translate('continue') : step === 2 ? translate('confirm') : null}
                        </Button>
                    </div>
                ]}
            >
                {modal?.type === "leverage" ?
                    <div className={`${Style.ChangeForm} d-flex`}>
                        <label style={dir === "ltr" ? { marginRight: "20px" } : { marginLeft: "20px" }}>{translate('funds_leverage')}</label>
                        <Select
                            value={leverageData.leverage}
                            style={{ width: "100%" }}
                            placeholder={translate('leverage')}
                            options={leverage}
                            onChange={(choice) => setLeverageData({ ...leverageData, leverage: choice })}
                        />
                    </div>
                    : modal?.type === "investor" ?
                    <div className={Style.ChangeForm}>
                        {step === 1 ?
                            <div className="d-md-flex">
                                <label className="text-nowrap" style={dir == "ltr" ? { marginRight: "25px" } : {marginLeft: "25px"}}>{translate('send_pin_via')}</label>
                                <Select
                                    className="w-100"
                                    options={[{ key: 1, value: "email", label: "Email" }]}
                                    defaultValue="email"
                                />
                            </div> : step === 2 ?
                                <div className={Style.ChangePassModal}>
                                    <div style={{ marginBottom: "15px" }} className="d-md-flex justify-content-between">
                                        <label>{translate('new_password')}</label>
                                        <div className={Style.ModalInput}>
                                            <Input.Password
                                                style={{ borderRadius: "25px" }}
                                                onChange={(e) => setPasswordData({ ...passwordData, password: e.target.value })} />
                                            <small>Must contain at least 1 upper case Latin letter, 1 lower case Latin letter, 1 digit, 1 special character and be at least 8 characters long</small>
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: "15px" }} className="d-md-flex justify-content-between">
                                        <label>{translate('confirm_password')}</label>
                                        <div className={Style.ModalInput}>
                                            <Input.Password
                                                style={{ borderRadius: "25px" }}
                                                onChange={(e) => setPasswordData({ ...passwordData, confirm: e.target.value })} />
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: "15px" }} className="d-md-flex justify-content-between">
                                        <label>{translate('pin')}</label>
                                        <div className={Style.ModalInput}>
                                            <Input
                                                style={{ borderRadius: "25px" }}
                                                onChange={(e) => setPasswordData({ ...passwordData, pin: e.target.value })} />
                                            <small>Please enter the PIN that has been sent to your email</small>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                    : modal?.type === "platform" ?
                    <div className={Style.ChangeForm}>
                        {step === 1 ?
                            <div className="d-md-flex">
                                <label className="text-nowrap" style={dir == "ltr" ? { marginRight: "25px" } : {marginLeft: "25px"}}>{translate('send_pin_via')}</label>
                                <Select
                                    className="w-100"
                                    options={[{ key: 1, value: "email", label: "Email" }]}
                                    defaultValue="email"
                                />
                            </div> : step === 2 ?
                                <div className={Style.ChangePassModal}>
                                    <div style={{ marginBottom: "15px" }} className="d-md-flex justify-content-between">
                                        <label>{translate('new_password')}</label>
                                        <div className={Style.ModalInput}>
                                            <Input.Password style={{ borderRadius: "25px" }} onChange={(e) => setPasswordData({ ...passwordData, password: e.target.value })} />
                                            <small>Must contain at least 1 upper case latin letter, 1 lower case latin
                                                letter, 1 digit and be at least 8 characters long</small>
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: "15px" }} className="d-md-flex justify-content-between">
                                        <label>{translate('confirm_password')}</label>
                                        <div className={Style.ModalInput}>
                                            <Input.Password style={{ borderRadius: "25px" }} onChange={(e) => setPasswordData({ ...passwordData, confirm: e.target.value })} />
                                        </div>
                                    </div>
                                    <div style={{ marginBottom: "15px" }} className="d-md-flex justify-content-between">
                                        <label>{translate('pin')}</label>
                                        <div className={Style.ModalInput}>
                                            <Input className="w-100" style={{ borderRadius: "25px" }} onChange={(e) => setPasswordData({ ...passwordData, pin: e.target.value })} />
                                            <small>Please enter the PIN that has been sent to your email</small>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                    : null
                }
            </Modal>
        </PanelLayout>
    )
}

export default Account;