import {post, put, get, remove, patch} from './axios';
import {
    accounts_types,
    accounts,
    accounts_loginSid,
    accounts_new,
    accounts_change_password,
    accounts_change_leverage,
    accounts_trading_history,
    accounts_available_amount_for_withdrawal,
    send_report,
    swap_free_request_form,
    swap_free_request,
    applications,
    applications_upload,
    applications_configs,
    applications_configs_id,
    cashback_breakdown,
    chart_name,
    charts_info,
    company_documents_for_accept,
    company_documents_accepted,
    company_documents_all,
    company_documents_accept,
    company_documents_id,
    systems_configuration,
    systems_file,
    systems_ping,
    deposit,
    deposit_demo,
    payment_systems_deposit,
    deposit_fees,
    payment_systems_deposit_loginSid,
    dict_currencies,
    dict_report_currency,
    dict_languages,
    login_direct,
    ping_send,
    login,
    two_fa_check,
    logout,
    forget_password,
    forget_password_pin,
    social_login_url,
    social_login_auth,
    social_login_connect,
    social_login_disconnect,
    documents,
    documents_id,
    documents_upload,
    documents_configs,
    ticket_comments_all,
    ticket_comments,
    ticket_comments_id_put,
    ticket_comments_id_delete,
    tickets_open,
    tickets_closed,
    ticket_categories,
    unread_tickets_count,
    tickets_id_get,
    tickets_id_delete,
    tickets_close_id,
    marketing_public_link_detail_id,
    public_link_detail_id,
    payment_details_upload,
    profile_messages,
    profile_messages_id,
    profile_messages_unread_count,
    profile_notification_preferences_options,
    profile_change_notification_preferences,
    profile_get,
    profile_put,
    profile_change_email,
    profile_change_phone,
    profile_change_password,
    profile_contact_manager_details,
    profile_update_custom_fields,
    profile_change_language,
    profile_verify_email,
    profile_verify_email_validate,
    profile_two_factor_qr_code,
    profile_two_factor_enable,
    profile_two_factor_disable,
    profile_two_factor_clear_trusted,
    profile_two_factor_backup_codes,
    registration_get,
    registration_post,
    registration_put,
    registration_send_pin,
    registration_confirmation,
    trading_calculator_get,
    trading_calculator_post,
    transactions_cancel,
    transactions_post,
    transfers_post,
    transfers_check,
    transfers_new,
    transfers_any_new,
    payout_post,
    withdrawal_detail_post,
    payment_systems_withdrawal_get,
    payment_systems_withdrawal_loginSid_get,
    ib_banners_post,
    ib_banners_campaigns_get,
    ib_banners_id_get_code_linkId_get,
    ib_referrals_post,
    ib_referrals_accounts_post,
    ib_commissions_post,
    ib_ib_transactions_post,
    ib_links_get,
    ib_links_landing_pages_get,
    ib_links_new_post,
    ib_links_id_edit_post,
    ib_links_id_delete,
    ib_public_link_detail_id_get,
    ib_reports_other_network_commissions_post,
    ib_reports_accounts_commissions_post,
    ib_reports_clients_commissions_post,
    ib_reports_cpa_payments_post,
    ib_tree_post,
    ib_referral_breakdown_id_post,
    ib_performance_dashboard_get,
    kyc_global_pass_screening_token,
    widgets,
    widgets_alias,
    payment_details_configs,
    downloads,
    payment_details_id,
    payment_details,
    payment_details_system_id,
    all_tickets,
    ticket
} from "./routes";

/* Accounts */

export const getAccountsTypesApi = (token) => {
    return get(accounts_types, {headers: {'Authorization': 'Bearer ' + token }});
};

export const getAccountApi = (data, token) => {
    return post(accounts, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const getAccountDetailsApi = (loginSid, token) => {
    return get(accounts_loginSid(loginSid), {headers: {'Authorization': 'Bearer ' + token }});
};

export const createNewAccountApi = (data, token) => {
    return post(accounts_new, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const changePasswordApi = (data, token) => {
    return post(accounts_change_password, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const changeLeverageApi = (data, token) => {
    return post(accounts_change_leverage, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const getTradingHistoryApi = (data, token) => {
    return post(accounts_trading_history, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const getAvailableAmountForWithdrawalApi = (loginSid, token) => {
    return get(accounts_available_amount_for_withdrawal(loginSid),{headers: {'Authorization': 'Bearer ' + token }});
};

export const sendReportApi = (data, token) => {
    return put(send_report, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const getSwapFreeRequestFormConfigApi = (loginSid, token) => {
    return get(swap_free_request_form(loginSid), {headers: {'Authorization': 'Bearer ' + token }});
};

export const createSwapFreeRequestApi = (loginSid, data) => {
    return post(swap_free_request, data);
};

/* Applications */

export const getApplicationsApi = (token) => {
    return get(applications, {headers: {'Authorization': 'Bearer ' + token }});
};

export const uploadApplicationApi = (data, token) => {
    return post(applications_upload, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const getApplicationConfigsApi = () => {
    return get(applications_configs);
};

export const getApplicationConfigByIdApi = (id) => {
    return get(applications_configs_id(id));
};

/* Cashback Breakdown */

export const cashbackBreakdownApi = (data) => {
    return post(cashback_breakdown, data);
};

/* Charts */

export const getChartByNameApi = (name, token) => {
    return get(chart_name(name), {headers: {'Authorization': 'Bearer ' + token }});
};

export const getChartsInfoApi = (token) => {
    return get(charts_info, {headers: {'Authorization': 'Bearer ' + token }});
};

/* Company Documents */

export const getCompanyDocumentsForAcceptApi = () => {
    return get(company_documents_for_accept);
};

export const getAcceptedCompanyDocumentsApi = (token) => {
    return get(company_documents_accepted, {headers: {'Authorization': 'Bearer ' + token }});
};

export const getAllCompanyDocumentsApi = () => {
    return get(company_documents_all);
};

export const acceptCompanyDocumentsApi = (data) => {
    return put(company_documents_accept, data);
};

export const getCompanyDocumentByIdApi = (id) => {
    return get(company_documents_id(id));
};

/* System */

export const getSystemConfigurationApi = () => {
    return get(systems_configuration);
};

export const getSystemFileApi = () => {
    return get(systems_file);
};

export const pingSystemApi = () => {
    return get(systems_ping);
};

/* Deposits */

export const depositApi = (data, token) => {
    return post(deposit, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const depositDemoApi = (data, token) => {
    return post(deposit_demo, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const getPaymentSystemsForDepositApi = (token) => {
    return get(payment_systems_deposit, {headers: {'Authorization': 'Bearer ' + token }});
};

export const getPaymentSystemsForDepositByLoginSidApi = (loginSid, token) => {
    return get(payment_systems_deposit_loginSid(loginSid), {headers: {'Authorization': 'Bearer ' + token }});
};

export const depositFeesApi = (data) => {
    return post(deposit_fees, data);
};

/* Dict */

export const getCurrenciesApi = (token) => {
    return get(dict_currencies, {headers: {'Authorization': 'Bearer ' + token }});
};

export const getReportCurrencyApi = () => {
    return get(dict_report_currency);
};

export const getAvailableLanguagesApi = (token) => {
    return get(dict_languages, {headers: {'Authorization': 'Bearer ' + token }});
};

/* Security */

/*export const getAccessTokenDirectApi = (token) => {
    return get(login_direct, {headers: {'Authorization': 'Bearer ' + token }});
};*/

export const sendPinApi = (data, token) => {
    return post(ping_send, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const getAccessTokenApi = (data) => {
    return post(login, data);
};

export const checkTwoFactorCodeApi = (data) => {
    return post(two_fa_check, data);
};

export const logoutApi = (data, token) => {
    return post(logout, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const getPinForForgotPasswordApi = (data) => {
    return post(forget_password, data);
};

export const changePasswordWithPinApi = (pin, data) => {
    return post(forget_password_pin(pin), data);
};

export const getSocialLoginAuthUrlApi = () => {
    return post(social_login_url);
};

export const userSocialLoginAuthApi = (data) => {
    return post(social_login_auth, data);
};

export const connectSocialServiceToUserApi = (data) => {
    return post(social_login_connect, data);
};

export const disconnectSocialServiceFromUserApi = (service) => {
    return post(social_login_disconnect(service));
};

/* Documents */


export const getDocumentsApi = () => {
    return get(documents);
};

export const deleteDocumentByIdApi = (id) => {
    return remove(documents_id(id));
};

export const uploadDocumentApi = (data) => {
    return post(documents_upload, data);
};

export const getDocumentConfigsApi = () => {
    return get(documents_configs);
};

/* Downloads */

export const getDownloadsApi = () => {
    return get(downloads);
};

/* Help Desk */

export const getTicketCommentsAllApi = () => {
    return post(ticket_comments_all);
};

export const createTicketCommentApi = (data, token) => {
    return post(ticket_comments, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const editTicketCommentApi = (id, data) => {
    return put(ticket_comments_id_put(id), data);
};

export const deleteTicketCommentApi = (id) => {
    return remove(ticket_comments_id_delete(id));
};

export const getUserOpenTicketsApi = () => {
    return get(tickets_open);
};

export const getUserClosedTicketsApi = (token) => {
    return get(tickets_closed, {headers: {'Authorization': 'Bearer ' + token }});
};

export const getAllTicketsApi = (data, token) => {
    return post(all_tickets, data,{headers: {'Authorization': 'Bearer ' + token }});
};

export const getTicketCategoriesApi = (token) => {
    return get(ticket_categories, {headers: {'Authorization': 'Bearer ' + token }});
};

export const createNewUserTicketApi = (data, token) => {
    return post(ticket, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const getUnreadTicketsCountApi = (token) => {
    return get(unread_tickets_count, {headers: {'Authorization': 'Bearer ' + token }});
};

export const getTicketDataApi = (id, token) => {
    return get(tickets_id_get(id), {headers: {'Authorization': 'Bearer ' + token }});
};

export const deleteTicketApi = (id) => {
    return remove(tickets_id_delete(id));
};

export const closeTicketApi = (id) => {
    return post(tickets_close_id(id));
};

/* Public */

export const getMarketingPublicLinkDetailApi = (id) => {
    return get(marketing_public_link_detail_id(id));
};

/*export const getIbPublicLinkDetailApi = (id) => {
    return get(public_link_detail_id(id));
};*/

/* Payment Details */

export const getPaymentDetailsByIdApi = (id, token) => {
    return get(payment_details_system_id(id), {headers: {'Authorization': 'Bearer ' + token }});
};

export const getPaymentDetailsApi = (token) => {
    return get(payment_details, {headers: {'Authorization': 'Bearer ' + token }});
};

export const deletePaymentDetailByIdApi = (id, token) => {
    return remove(payment_details_id(id), {headers: {'Authorization': 'Bearer ' + token }});
};

export const uploadPaymentDetailApi = (data, token) => {
    return post(payment_details_upload, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const getPaymentDetailsConfigsApi = (token) => {
    return get(payment_details_configs, {headers: {'Authorization': 'Bearer ' + token }});
};

/* Profile */

export const getProfileMessagesApi = (data, token) => {
    return post(profile_messages, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const viewProfileMessageApi = (id, token) => {
    return get(profile_messages_id(id), {headers: {'Authorization': 'Bearer ' + token }});
};

export const getUnreadMessageCountApi = (token) => {
    return get(profile_messages_unread_count, {headers: {'Authorization': 'Bearer ' + token }});
};

export const getNotificationPreferencesOptionsApi = (token) => {
    return get(profile_notification_preferences_options, {headers: {'Authorization': 'Bearer ' + token }});
};

export const updateNotificationPreferencesApi = (data, token) => {
    return post(profile_change_notification_preferences, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const getProfileApi = (token) => {
    return get(profile_get, {headers: {'Authorization': 'Bearer ' + token }});
};

export const updateProfileApi = (data, token) => {
    return put(profile_put, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const changeEmailApi = (data, token) => {
    return post(profile_change_email, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const changePhoneApi = (data, token) => {
    return post(profile_change_phone, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const changeProfilePasswordApi = (data, token) => {
    return post(profile_change_password, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const getContactManagerDetailsApi = (token) => {
    return get(profile_contact_manager_details, {headers: {'Authorization': 'Bearer ' + token }});
};

export const updateCustomFieldsApi = (data) => {
    return post(profile_update_custom_fields, data);
};

export const changeLanguageApi = (data, token) => {
    return post(profile_change_language, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const verifyEmailApi = () => {
    return post(profile_verify_email);
};

export const validateEmailVerificationApi = (hash) => {
    return post(profile_verify_email_validate(hash));
};

/* Two Factor */

export const generateTwoFactorQRCodeApi = (token) => {
    return post(profile_two_factor_qr_code, null, {headers: {'Authorization': 'Bearer ' + token }});
};

export const enableTwoFactorApi = (data, token) => {
    return put(profile_two_factor_enable, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const disableTwoFactorApi = (token) => {
    return remove(profile_two_factor_disable, {headers: {'Authorization': 'Bearer ' + token }});
};

export const clearTrustedDevicesApi = (token) => {
    return patch(profile_two_factor_clear_trusted, {headers: {'Authorization': 'Bearer ' + token }});
};

export const generateNewBackupCodesApi = (token) => {
    return post(profile_two_factor_backup_codes, null, {headers: {'Authorization': 'Bearer ' + token }});
};

/* Registration */

export const getRegistrationFormConfigApi = () => {
    return get(registration_get);
};

export const registerClientApi = (data) => {
    return post(registration_post, data);
};

export const repeatCabinetRegistrationApi = (data) => {
    return put(registration_put, data);
};

export const sendPinForRegistrationApi = (data) => {
    return post(registration_send_pin, data);
};

export const confirmRegistrationApi = (data) => {
    return post(registration_confirmation, data);
};

/* TradingCalculator */

export const getTradingCalculatorDataApi = (token) => {
    return get(trading_calculator_get, {headers: {'Authorization': 'Bearer ' + token }});
};

export const doTradeCalculationApi = (data, token) => {
    return post(trading_calculator_post, data, {headers: {'Authorization': 'Bearer ' + token }});
};

/* Transactions */

export const cancelTransactionApi = (id, token) => {
    return patch(transactions_cancel(id));
};

export const getTransactionApi = (data, token) => {
    return post(transactions_post, data, {headers: {'Authorization': 'Bearer ' + token }});
};

/* Transfers */

export const createTransferApi = (data, token) => {
    return post(transfers_post, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const checkTransferApi = (data, token) => {
    return post(transfers_check, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const createNewTransferApi = (data, token) => {
    return post(transfers_new, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const createAnyNewTransferApi = (data) => {
    return post(transfers_any_new, data);
};

/* Withdrawals */

export const payoutApi = (data, token) => {
    return post(payout_post, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const getWithdrawalDetailApi = (data, token) => {
    return post(withdrawal_detail_post, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const getAvailableWithdrawalConfigsApi = (token) => {
    return get(payment_systems_withdrawal_get, {headers: {'Authorization': 'Bearer ' + token }});
};

export const getAvailableWithdrawalConfigsForLoginSidApi = (loginSid, token) => {
    return get(payment_systems_withdrawal_loginSid_get(loginSid), {headers: {'Authorization': 'Bearer ' + token }});
};

/* IB */

export const createIbBannersApi = (data, token) => {
    return post(ib_banners_post, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const getIbBannersCampaignsApi = () => {
    return get(ib_banners_campaigns_get);
};

export const getIbBannersCodeLinkApi = (id, linkId) => {
    return get(ib_banners_id_get_code_linkId_get(id, linkId));
};

export const createIbReferralsApi = (data, token) => {
    return post(ib_referrals_post, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const createIbReferralsAccountsApi = (data, token) => {
    return post(ib_referrals_accounts_post, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const createIbCommissionsApi = (data, token) => {
    return post(ib_commissions_post, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const getIbTransactionsApi = (data, token) => {
    return post(ib_ib_transactions_post, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const getIbLinksApi = (token) => {
    return get(ib_links_get, {headers: {'Authorization': 'Bearer ' + token }});
};

export const getIbLinksLandingPagesApi = (token) => {
    return get(ib_links_landing_pages_get, {headers: {'Authorization': 'Bearer ' + token }});
};

export const createIbNewLinkApi = (data, token) => {
    return post(ib_links_new_post, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const editIbLinkApi = (id, data, token) => {
    return post(ib_links_id_edit_post(id), data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const deleteIbLinkApi = (id, token) => {
    return remove(ib_links_id_delete(id), {headers: {'Authorization': 'Bearer ' + token }});
};

export const getIbPublicLinkDetailApi = (id) => {
    return get(ib_public_link_detail_id_get(id));
};

export const getIbReportsOtherNetworkCommissionsApi = (data, token) => {
    return post(ib_reports_other_network_commissions_post, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const getIbReportsAccountsCommissionsApi = (data, token) => {
    return post(ib_reports_accounts_commissions_post, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const getIbReportsClientsCommissionsApi = (data, token) => {
    return post(ib_reports_clients_commissions_post, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const getIbReportsCpaPaymentsApi = (data, token) => {
    return post(ib_reports_cpa_payments_post, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const getIbTreeDataApi = (data, token) => {
    return post(ib_tree_post, data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const getIbReferralBreakdownApi = (id, data, token) => {
    return post(ib_referral_breakdown_id_post(id), data, {headers: {'Authorization': 'Bearer ' + token }});
};

export const getIbPerformanceDashboardApi = (data, token) => {
    let url = ib_performance_dashboard_get;
    if (data) { url += `?${Object.keys(data).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`).join('&')}` }
    return get(url, {headers: {'Authorization': 'Bearer ' + token }});
};

/* KYC */

export const getKycGlobalPassScreeningTokenApi = () => {
    return get(kyc_global_pass_screening_token);
};

/* Widgets */

export const getWidgetsApi = () => {
    return get(widgets);
};

export const getWidgetByAliasApi = (alias) => {
    return get(widgets_alias(alias));
};